import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Typography,
  Space,
  message,
  Divider,
  Row,
  Col,
  Card,
  Tooltip,
  Modal,
  FormListFieldData,
  Upload,
  UploadFile,
  Skeleton,
  Alert,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DeleteOutlined,
  InboxOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useForm,
  Controller,
  useFieldArray,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  createVocab,
  editVocab,
  getVocab,
  searchMeanings,
  searchWords,
  deleteWordSynonym,
  deleteWordAntonym,
  deleteMeaning,
  deleteMeaningSynonym,
  deleteMeaningAntonym,
  deleteMeaningWordSynonym,
  deleteMeaningWordAntonym,
  deleteExample,
  deleteAlternativeCharacter,
  deleteAlternativePronounce,
  deleteMixWord,
  deleteCorruptedPronounce,
  getAllLatestIndexIds,
  downloadSound,
} from "../api/VocabAPI";
import {
  IVocab,
  IVocabCreateRequest,
  IVocabUpdateRequest,
  MeaningOption,
  SearchMeaningsResponse,
  SearchWordsResponse,
  VocabSchema,
  WordOption,
} from "../type/VocabManagementType";
import { getDictionaryList } from "../../dictionary/api/DictionaryAPI";
import { IDictionary } from "../../dictionary/type/DictionaryManagementType";
import { useMediaQuery } from "react-responsive";
import {
  generateInitialIndexId,
  getNextIndexId,
  IndexType,
} from "../../../utils/indexIdUtils";
import { ReactComponent as StopIcon } from "../../../assets/svg/stop-icon.svg";

const { Option } = Select;
const { Dragger } = Upload;

const defaultValues: IVocab = {
  hanji: "",
  roman: "",
  category: "",
  subCategory: "",
  etymology: "",
  dictionaryId: "",
  wordSynonym: [],
  wordAntonym: [],
  meaning: [
    {
      meaningHokkien: "",
      meaningTagalog: "",
      meaningIndonesia: "",
      meaningMalay: "",
      example: [
        {
          exampleTagalog: "",
          exampleIndonesia: "",
        },
      ],
    },
  ],
  alternativeCharacter: [],
  alternativePronounce: [],
  mixWord: [],
  corruptedPronounce: [],
  accent: {
    accentLugang: "",
    accentSanxia: "",
    accentTaipei: "",
    accentYilan: "",
    accentTainan: "",
    accentGaoxiong: "",
    accentJinmen: "",
    accentMagong: "",
    accentXinchu: "",
    accentTaichung: "",
  },
};

type FieldWithPossibleId = FormListFieldData & { id?: string };

const { Text, Title } = Typography;

const VocabAddEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isNewVocab = id === "new" || location.pathname === "/vocab/new";
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [wordSynonymSearchTerm, setWordSynonymSearchTerm] = useState("");
  const [wordAntonymSearchTerm, setWordAntonymSearchTerm] = useState("");
  const [meaningSynonymSearchTerm, setMeaningSynonymSearchTerm] = useState("");
  const [meaningAntonymSearchTerm, setMeaningAntonymSearchTerm] = useState("");
  const [meaningWordSynonymSearchTerm, setMeaningWordSynonymSearchTerm] =
    useState("");
  const [meaningWordAntonymSearchTerm, setMeaningWordAntonymSearchTerm] =
    useState("");
  const [soundFile, setSoundFile] = useState<UploadFile | null>(null);
  const [currentSoundUrl, setCurrentSoundUrl] = useState<string | null>(null);

  const [listDelete, setListDelete] = useState({});
  const [fileInfo, setFileInfo] = useState<{
    size: string;
    type: string;
  } | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<IVocab>({
    resolver: zodResolver(VocabSchema),
    defaultValues,
  });

  const selectedDictionary = watch("dictionaryId");
  const isDictionarySelected = !!selectedDictionary;

  const tooltipTitle = "Please select a dictionary first";

  // Use useFieldArray for all array fields
  const {
    fields: meaningFields,
    append: appendMeaning,
    remove: removeMeaning,
  } = useFieldArray({ control, name: "meaning" });

  const {
    fields: wordSynonymFields,
    append: appendWordSynonym,
    remove: removeWordSynonym,
  } = useFieldArray({ control, name: "wordSynonym" });

  const {
    fields: wordAntonymFields,
    append: appendWordAntonym,
    remove: removeWordAntonym,
  } = useFieldArray({ control, name: "wordAntonym" });

  const {
    fields: alternativeCharacterFields,
    append: appendAlternativeCharacter,
    remove: removeAlternativeCharacter,
  } = useFieldArray({ control, name: "alternativeCharacter" });

  const {
    fields: alternativePronounceFields,
    append: appendAlternativePronounce,
    remove: removeAlternativePronounce,
  } = useFieldArray({ control, name: "alternativePronounce" });

  const {
    fields: mixWordFields,
    append: appendMixWord,
    remove: removeMixWord,
  } = useFieldArray({ control, name: "mixWord" });

  const {
    fields: corruptedPronounceFields,
    append: appendCorruptedPronounce,
    remove: removeCorruptedPronounce,
  } = useFieldArray({ control, name: "corruptedPronounce" });

  const { data: vocabData, isLoading: isVocabLoading } = useQuery({
    queryKey: ["vocab", id],
    queryFn: () => getVocab(id),
    enabled: !isNewVocab,
  });

  const { data: dictionaryData, isLoading: isDictionaryLoading } = useQuery({
    queryKey: ["dictionary"],
    queryFn: () => getDictionaryList({ params: { pageSize: 100 } }),
  });

  const { data: wordSynonymOptions, isLoading: isWordSynonymSearchLoading } =
    useQuery<SearchWordsResponse>({
      queryKey: ["wordSearch", "wordSynonym", wordSynonymSearchTerm],
      queryFn: () => searchWords(wordSynonymSearchTerm, watch("dictionaryId")),
      enabled: !!wordSynonymSearchTerm,
    });

  const { data: wordAntonymOptions, isLoading: isWordAntonymSearchLoading } =
    useQuery<SearchWordsResponse>({
      queryKey: ["wordSearch", "wordAntonym", wordAntonymSearchTerm],
      queryFn: () => searchWords(wordAntonymSearchTerm, watch("dictionaryId")),
      enabled: !!wordAntonymSearchTerm,
    });

  const {
    data: meaningSynonymOptions,
    isLoading: isMeaningSynonymSearchLoading,
  } = useQuery<SearchMeaningsResponse>({
    queryKey: ["meaningSearch", "meaningSynonym", meaningSynonymSearchTerm],
    queryFn: () =>
      searchMeanings(meaningSynonymSearchTerm, watch("dictionaryId")),
    enabled: !!meaningSynonymSearchTerm && !!watch("dictionaryId"),
  });

  const {
    data: meaningAntonymOptions,
    isLoading: isMeaningAntonymSearchLoading,
  } = useQuery<SearchMeaningsResponse>({
    queryKey: ["meaningSearch", "meaningAntonym", meaningAntonymSearchTerm],
    queryFn: () =>
      searchMeanings(meaningAntonymSearchTerm, watch("dictionaryId")),
    enabled: !!meaningAntonymSearchTerm && !!watch("dictionaryId"),
  });

  const {
    data: meaningWordSynonymOptions,
    isLoading: isMeaningWordSynonymSearchLoading,
  } = useQuery<SearchWordsResponse>({
    queryKey: [
      "wordSearch",
      "meaningWordSynonym",
      meaningWordSynonymSearchTerm,
    ],
    queryFn: () =>
      searchWords(meaningWordSynonymSearchTerm, watch("dictionaryId")),
    enabled: !!meaningWordSynonymSearchTerm,
  });

  const {
    data: meaningWordAntonymOptions,
    isLoading: isMeaningWordAntonymSearchLoading,
  } = useQuery<SearchWordsResponse>({
    queryKey: [
      "wordSearch",
      "meaningWordAntonym",
      meaningWordAntonymSearchTerm,
    ],
    queryFn: () =>
      searchWords(meaningWordAntonymSearchTerm, watch("dictionaryId")),
    enabled: !!meaningWordAntonymSearchTerm,
  });

  const createMutation = useMutation({
    mutationFn: (data: FormData | IVocab) => createVocab(data),
    onSuccess: () => {
      message.success("Vocab created successfully!");
      navigate("/vocab");
      queryClient.invalidateQueries({ queryKey: ["vocab"] });
    },
    onError: (error) => {
      console.error("Error creating vocab:", error);
      message.error("Failed to create vocab: " + error.message);
    },
  });

  const editMutation = useMutation({
    mutationFn: (data: FormData | IVocab) => {
      if (!id) throw new Error("ID is required for editing");
      return editVocab(id, data);
    },
    onSuccess: () => {
      message.success("Vocab updated successfully!");
      navigate("/vocab");
      queryClient.invalidateQueries({ queryKey: ["vocab"] });
    },
    onError: (error) => {
      console.error("Error updating vocab:", error);
      message.error("Failed to update vocab: " + error.message);
    },
  });

  const [selectedDictionaryName, setSelectedDictionaryName] =
    useState<string>("");

  const { data: latestIndexIds, refetch: refetchLatestIndexIds } = useQuery({
    queryKey: ["latestIndexIds"],
    queryFn: () => getAllLatestIndexIds(selectedDictionaryName),
    enabled: false, // This keeps it as a lazy query
  });

  useEffect(() => {
    let formattedData: IVocab;

    if (vocabData) {
      formattedData = {
        hanji: vocabData.hanji || "",
        roman: vocabData.roman || "",
        category: vocabData.category || "",
        subCategory: vocabData.subCategory || "",
        etymology: vocabData.etymology || "",
        dictionaryId: vocabData.dictionaryId || "",
        wordSynonym:
          vocabData.wordSynonyms?.map((item: any) => ({
            id: item._id,
            relativeWordIndexId: item.relativeWordIndexId,
            relativeHanji: item.relativeHanji,
            relativeRoman: item.relativeRoman,
          })) || [],
        wordAntonym:
          vocabData.wordAntonyms?.map((item: any) => ({
            id: item._id,
            relativeWordIndexId: item.relativeWordIndexId,
            relativeHanji: item.relativeHanji,
            relativeRoman: item.relativeRoman,
          })) || [],
        meaning:
          vocabData.meanings?.map((item: any) => ({
            id: item._id,
            partOfSpeech: item.partOfSpeech || "",
            meaningChinese: item.meaningChinese || "",
            meaningEnglish: item.meaningEnglish || "",
            meaningHokkien: item.meaningHokkien || "",
            meaningTagalog: item.meaningTagalog || "",
            meaningIndonesia: item.meaningIndonesia || "",
            meaningMalay: item.meaningMalay || "",
            meaningSynonym:
              item.meaningSynonyms?.map((syn: any) => ({
                id: syn._id,
                relativeMeaningIndexId: syn.relativeMeaningIndexId,
                relativePartOfSpeech: syn.relativePartOfSpeech,
                relativeMeaningChinese: syn.relativeMeaningChinese,
                relativeHanji: syn.relativeHanji,
                relativeRoman: syn.relativeRoman,
              })) || [],
            meaningAntonym:
              item.meaningAntonyms?.map((ant: any) => ({
                id: ant._id,
                relativeMeaningIndexId: ant.relativeMeaningIndexId,
                relativePartOfSpeech: ant.relativePartOfSpeech,
                relativeMeaningChinese: ant.relativeMeaningChinese,
                relativeHanji: ant.relativeHanji,
                relativeRoman: ant.relativeRoman,
              })) || [],
            meaningWordSynonym:
              item.meaningWordSynonyms?.map((syn: any) => ({
                id: syn._id,
                relativeWordIndexId: syn.relativeWordIndexId,
                relativeHanji: syn.relativeHanji,
                relativeRoman: syn.relativeRoman,
              })) || [],
            meaningWordAntonym:
              item.meaningWordAntonyms?.map((ant: any) => ({
                id: ant._id,
                relativeWordIndexId: ant.relativeWordIndexId,
                relativeHanji: ant.relativeHanji,
                relativeRoman: ant.relativeRoman,
              })) || [],
            example:
              item.examples?.map((ex: any) => ({
                id: ex._id,
                exampleHanji: ex.exampleHanji || "",
                exampleRoman: ex.exampleRoman || "",
                exampleChinese: ex.exampleChinese || "",
                exampleEnglish: ex.exampleEnglish || "",
                exampleMalay: ex.exampleMalay || "",
                exampleTagalog: ex.exampleTagalog || "",
                exampleIndonesia: ex.exampleIndonesia || "",
              })) || [],
          })) || [],
        alternativeCharacter:
          vocabData.alternativeCharacters?.map((item: any) => ({
            id: item._id,
            alternativeCharacterHanji: item.alternativeCharacterHanji || "",
          })) || [],
        alternativePronounce:
          vocabData.alternativePronounces?.map((item: any) => ({
            id: item._id,
            alternativePronounceRoman: item.alternativePronounceRoman || "",
          })) || [],
        mixWord:
          vocabData.mixWords?.map((item: any) => ({
            id: item._id,
            mixWordRoman: item.mixWordRoman || "",
          })) || [],
        corruptedPronounce:
          vocabData.corruptedPronounces?.map((item: any) => ({
            id: item._id,
            corruptedPronounceRoman: item.corruptedPronounceRoman || "",
          })) || [],
        accent: vocabData.accents
          ? {
              id: vocabData.accents._id,
              accentIndexId: vocabData.accents.accentIndexId,
              accentLugang: vocabData.accents.accentLugang || "",
              accentSanxia: vocabData.accents.accentSanxia || "",
              accentTaipei: vocabData.accents.accentTaipei || "",
              accentYilan: vocabData.accents.accentYilan || "",
              accentTainan: vocabData.accents.accentTainan || "",
              accentGaoxiong: vocabData.accents.accentGaoxiong || "",
              accentJinmen: vocabData.accents.accentJinmen || "",
              accentMagong: vocabData.accents.accentMagong || "",
              accentXinchu: vocabData.accents.accentXinchu || "",
              accentTaichung: vocabData.accents.accentTaichung || "",
            }
          : undefined,
      };

      // Set the dictionary name when editing
      if (vocabData.dictionaryId) {
        const dictionary = dictionaryData?.results.find(
          (dict: IDictionary) => dict.id === vocabData.dictionaryId
        );
        if (dictionary) {
          setSelectedDictionaryName(dictionary.name);
        }
      }

      if (vocabData.recordURL) {
        setCurrentSoundUrl(vocabData.recordURL);
      }
    } else {
      formattedData = { ...defaultValues };
    }

    reset(formattedData);
  }, [vocabData, isNewVocab, reset, dictionaryData]);

  const handleSearch = (
    value: string,
    type:
      | "wordSynonym"
      | "wordAntonym"
      | "meaningSynonym"
      | "meaningAntonym"
      | "meaningWordSynonym"
      | "meaningWordAntonym"
  ) => {
    switch (type) {
      case "wordSynonym":
        setWordSynonymSearchTerm(value);
        break;
      case "wordAntonym":
        setWordAntonymSearchTerm(value);
        break;
      case "meaningSynonym":
        setMeaningSynonymSearchTerm(value);
        break;
      case "meaningAntonym":
        setMeaningAntonymSearchTerm(value);
        break;
      case "meaningWordSynonym":
        setMeaningWordSynonymSearchTerm(value);
        break;
      case "meaningWordAntonym":
        setMeaningWordAntonymSearchTerm(value);
        break;
    }
  };

  const handleRemoveOnBackend = async (
    id: string | undefined,
    type: string,
    index: number,
    parentIndex?: number
  ) => {
    if (!id) {
      message.error("ID is required for removing");
      return;
    }
    switch (type) {
      case "wordSynonym":
        await deleteWordSynonym(id);
        break;
      case "wordAntonym":
        await deleteWordAntonym(id);
        break;
      case "meaning":
        await deleteMeaning(id);
        break;
      case "meaningSynonym":
        await deleteMeaningSynonym(id);
        break;
      case "meaningAntonym":
        await deleteMeaningAntonym(id);
        break;
      case "meaningWordSynonym":
        await deleteMeaningWordSynonym(id);
        break;
      case "meaningWordAntonym":
        await deleteMeaningWordAntonym(id);
        break;
      case "example":
        await deleteExample(id);
        break;
      case "alternativeCharacter":
        await deleteAlternativeCharacter(id);
        break;
      case "alternativePronounce":
        await deleteAlternativePronounce(id);
        break;
      case "mixWord":
        await deleteMixWord(id);
        break;
      case "corruptedPronounce":
        await deleteCorruptedPronounce(id);
        break;
      default:
        throw new Error("Invalid remove type");
    }
  };

  const handleSoundUpload = (file: UploadFile) => {
    console.log("File uploaded:", file);
    const isWebm = file.type === "video/webm" || file.type === "audio/webm";
    if (!isWebm) {
      message.error("Only WebM files are allowed. Please select a WebM file.");
      return Upload.LIST_IGNORE;
    }

    // Check if file size is defined
    if (typeof file.size !== "number") {
      message.error(
        "We couldn't determine the file size. Please try uploading again."
      );
      return Upload.LIST_IGNORE;
    }

    // Check file size (50MB = 50 * 1024 * 1024 bytes)
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error(
        "The file is too large. Please choose a file smaller than 50MB."
      );
      return Upload.LIST_IGNORE;
    }

    setSoundFile(file);
    setCurrentSoundUrl(null);

    // Set file info
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    setFileInfo({
      size: `${fileSizeInMB} MB`,
      type: file.type || "Unknown",
    });

    return false; // Prevent automatic upload
  };

  const handleSoundRemove = () => {
    setSoundFile(null);
    setCurrentSoundUrl(null);
    setFileInfo(null);
  };

  const handlePlaySound = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
          message.error(
            "Failed to play audio. Please check the file format and try again."
          );
        });
        setIsPlaying(true);
      }
    } else {
      message.error("Audio player is not available.");
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleEnded = () => setIsPlaying(false);
      audioElement.addEventListener("ended", handleEnded);
      return () => {
        audioElement.removeEventListener("ended", handleEnded);
      };
    }
  }, []);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadSound = async () => {
    if (id && currentSoundUrl) {
      try {
        setIsDownloading(true);
        const { data, filename } = await downloadSound(id);
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        message.success("Download started. Check your downloads folder.");

        // Clean up the object URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading sound:", error);
        message.error("Failed to download sound file.");
      } finally {
        setIsDownloading(false);
      }
    } else {
      message.error("No audio file available for download.");
    }
  };

  const prepareData = (data: any, isEditing: boolean): any => {
    if (Array.isArray(data)) {
      return data.map((item) => prepareData(item, isEditing));
    } else if (typeof data === "object" && data !== null) {
      const newObj: any = {};
      for (const [key, value] of Object.entries(data)) {
        if (value !== null && value !== undefined) {
          // Skip dictionaryId when editing
          if (isEditing && key === "dictionaryId") continue;

          const cleaned = prepareData(value, isEditing);
          if (Array.isArray(cleaned) && cleaned.length > 0) {
            newObj[key] = cleaned;
          } else if (
            typeof cleaned === "object" &&
            Object.keys(cleaned).length > 0
          ) {
            newObj[key] = cleaned;
          } else if (!Array.isArray(cleaned) && typeof cleaned !== "object") {
            newObj[key] = cleaned;
          }
        }
      }
      return newObj;
    }
    return data;
  };

  const removeEmptyValues = (obj: any): any => {
    if (Array.isArray(obj)) {
      const filteredArray = obj
        .map(removeEmptyValues)
        .filter((item) => item != null && item !== "");
      return filteredArray.length > 0 ? filteredArray : undefined;
    } else if (typeof obj === "object" && obj !== null) {
      const newObj: any = {};
      for (const [key, value] of Object.entries(obj)) {
        const cleanedValue = removeEmptyValues(value);
        if (cleanedValue != null && cleanedValue !== "") {
          newObj[key] = cleanedValue;
        }
      }
      return Object.keys(newObj).length > 0 ? newObj : undefined;
    }
    return obj != null && obj !== "" ? obj : undefined;
  };

  const [duplicateError, setDuplicateError] = useState<
    { type: string; value: string; field: string }[] | null
  >(null);

  // Function to check for duplicates
  const checkForDuplicates = (values: IVocab) => {
    const allWords = new Set<string>();
    const allMeanings = new Set<string>();
    const duplicates: { type: string; value: string; field: string }[] = [];

    const addToSet = (
      set: Set<string>,
      value: string,
      type: string,
      field: string
    ) => {
      // Ignore empty values
      if (!value.trim()) return;

      if (set.has(value)) {
        duplicates.push({ type, value, field });
      } else {
        set.add(value);
      }
    };

    // Check word synonyms and antonyms
    values.wordSynonym?.forEach((syn, index) =>
      addToSet(
        allWords,
        syn.relativeWordIndexId ?? "",
        "Word Synonym",
        `Word Synonyms (Item ${index + 1})`
      )
    );
    values.wordAntonym?.forEach((ant, index) =>
      addToSet(
        allWords,
        ant.relativeWordIndexId ?? "",
        "Word Antonym",
        `Word Antonyms (Item ${index + 1})`
      )
    );

    // Check meanings and their related fields
    values.meaning?.forEach((meaning, meaningIndex) => {
      meaning.meaningSynonym?.forEach((syn, index) =>
        addToSet(
          allMeanings,
          syn.relativeMeaningIndexId ?? "",
          "Meaning Synonym",
          `Meaning ${meaningIndex + 1} - Synonyms (Item ${index + 1})`
        )
      );
      meaning.meaningAntonym?.forEach((ant, index) =>
        addToSet(
          allMeanings,
          ant.relativeMeaningIndexId ?? "",
          "Meaning Antonym",
          `Meaning ${meaningIndex + 1} - Antonyms (Item ${index + 1})`
        )
      );
      meaning.meaningWordSynonym?.forEach((syn, index) =>
        addToSet(
          allWords,
          syn.relativeWordIndexId ?? "",
          "Meaning Word Synonym",
          `Meaning ${meaningIndex + 1} - Word Synonyms (Item ${index + 1})`
        )
      );
      meaning.meaningWordAntonym?.forEach((ant, index) =>
        addToSet(
          allWords,
          ant.relativeWordIndexId ?? "",
          "Meaning Word Antonym",
          `Meaning ${meaningIndex + 1} - Word Antonyms (Item ${index + 1})`
        )
      );
    });

    return duplicates;
  };

  const generateNewIndexIds = (
    latestIndexIds: Record<IndexType, string>,
    dictionaryName: string,
    isNewVocab: boolean,
    formValues: IVocab
  ) => {
    const newIndexIds: Partial<IVocab> = {};

    const generateIndexId = (type: IndexType, existingId?: string) => {
      if (existingId) return existingId;
      const newId = latestIndexIds[type]
        ? getNextIndexId(latestIndexIds[type])
        : generateInitialIndexId(dictionaryName, type);
      latestIndexIds[type] = newId; // Update the latest ID for the next item
      return newId;
    };

    if (isNewVocab) {
      newIndexIds.wordIndexId = generateIndexId("word");

      // Generate new index IDs for other fields
      newIndexIds.meaning = formValues.meaning?.map((meaning) => ({
        meaningIndexId: generateIndexId("meaning"),
        ...meaning,
        meaningSynonym: meaning.meaningSynonym?.map((syn) => ({
          ...syn,
          meaningSynonymIndexId: generateIndexId("meaningSynonym"),
        })),
        meaningAntonym: meaning.meaningAntonym?.map((ant) => ({
          ...ant,
          meaningAntonymIndexId: generateIndexId("meaningAntonym"),
        })),
        meaningWordSynonym: meaning.meaningWordSynonym?.map((syn) => ({
          ...syn,
          meaningWordSynonymIndexId: generateIndexId("meaningWordSynonym"),
        })),
        meaningWordAntonym: meaning.meaningWordAntonym?.map((ant) => ({
          ...ant,
          meaningWordAntonymIndexId: generateIndexId("meaningWordAntonym"),
        })),
        example: meaning.example?.map((ex) => ({
          ...ex,
          exampleIndexId: generateIndexId("example"),
        })),
      }));

      newIndexIds.wordSynonym = formValues.wordSynonym?.map((syn) => ({
        ...syn,
        wordSynonymIndexId: generateIndexId("wordSynonym"),
      }));

      newIndexIds.wordAntonym = formValues.wordAntonym?.map((ant) => ({
        ...ant,
        wordAntonymIndexId: generateIndexId("wordAntonym"),
      }));

      newIndexIds.alternativeCharacter = formValues.alternativeCharacter?.map(
        (char) => ({
          ...char,
          alternativeCharacterIndexId: generateIndexId("alternativeCharacter"),
        })
      );

      newIndexIds.alternativePronounce = formValues.alternativePronounce?.map(
        (pron) => ({
          ...pron,
          alternativePronounceIndexId: generateIndexId("alternativePronounce"),
        })
      );

      newIndexIds.mixWord = formValues.mixWord?.map((mix) => ({
        ...mix,
        mixWordIndexId: generateIndexId("mixWord"),
      }));

      newIndexIds.corruptedPronounce = formValues.corruptedPronounce?.map(
        (corrupt) => ({
          ...corrupt,
          corruptedPronounceIndexId: generateIndexId("corruptedPronounce"),
        })
      );

      // Check if any accent field is non-empty
      const hasNonEmptyAccent = Object.values(formValues.accent || {}).some(
        (value) => value && value.trim() !== ""
      );

      if (hasNonEmptyAccent) {
        newIndexIds.accent = {
          ...formValues.accent,
          accentIndexId: generateIndexId("accent"),
        };
      } else {
        newIndexIds.accent = formValues.accent; // Keep the accent object without an accentIndexId
      }
    } else {
      // For editing, preserve existing index IDs and generate new ones for newly added items
      newIndexIds.wordIndexId = formValues.wordIndexId;
      newIndexIds.meaning = formValues.meaning?.map((meaning) => ({
        ...meaning,
        meaningIndexId: generateIndexId("meaning", meaning.meaningIndexId),
        meaningSynonym: meaning.meaningSynonym?.map((syn) => ({
          ...syn,
          meaningSynonymIndexId: generateIndexId(
            "meaningSynonym",
            syn.meaningSynonymIndexId
          ),
        })),
        meaningAntonym: meaning.meaningAntonym?.map((ant) => ({
          ...ant,
          meaningAntonymIndexId: generateIndexId(
            "meaningAntonym",
            ant.meaningAntonymIndexId
          ),
        })),
        meaningWordSynonym: meaning.meaningWordSynonym?.map((syn) => ({
          ...syn,
          meaningWordSynonymIndexId: generateIndexId(
            "meaningWordSynonym",
            syn.meaningWordSynonymIndexId
          ),
        })),
        meaningWordAntonym: meaning.meaningWordAntonym?.map((ant) => ({
          ...ant,
          meaningWordAntonymIndexId: generateIndexId(
            "meaningWordAntonym",
            ant.meaningWordAntonymIndexId
          ),
        })),
        example: meaning.example?.map((ex) => ({
          ...ex,
          exampleIndexId: generateIndexId("example", ex.exampleIndexId),
        })),
      }));
      newIndexIds.wordSynonym = formValues.wordSynonym?.map((syn) => ({
        ...syn,
        wordSynonymIndexId: generateIndexId(
          "wordSynonym",
          syn.wordSynonymIndexId
        ),
      }));
      newIndexIds.wordAntonym = formValues.wordAntonym?.map((ant) => ({
        ...ant,
        wordAntonymIndexId: generateIndexId(
          "wordAntonym",
          ant.wordAntonymIndexId
        ),
      }));
      newIndexIds.alternativeCharacter = formValues.alternativeCharacter?.map(
        (char) => ({
          ...char,
          alternativeCharacterIndexId: generateIndexId(
            "alternativeCharacter",
            char.alternativeCharacterIndexId
          ),
        })
      );
      newIndexIds.alternativePronounce = formValues.alternativePronounce?.map(
        (pron) => ({
          ...pron,
          alternativePronounceIndexId: generateIndexId(
            "alternativePronounce",
            pron.alternativePronounceIndexId
          ),
        })
      );
      newIndexIds.mixWord = formValues.mixWord?.map((mix) => ({
        ...mix,
        mixWordIndexId: generateIndexId("mixWord", mix.mixWordIndexId),
      }));
      newIndexIds.corruptedPronounce = formValues.corruptedPronounce?.map(
        (corrupt) => ({
          ...corrupt,
          corruptedPronounceIndexId: generateIndexId(
            "corruptedPronounce",
            corrupt.corruptedPronounceIndexId
          ),
        })
      );
      newIndexIds.accent = {
        ...formValues.accent,
        accentIndexId: generateIndexId(
          "accent",
          formValues.accent?.accentIndexId
        ),
      };
    }

    return newIndexIds;
  };

  // Modify the onSubmit function
  const onSubmit = async (values: IVocab) => {
    console.log("value: ", values);

    const duplicates = checkForDuplicates(values);
    if (duplicates.length > 0) {
      setDuplicateError(duplicates);
      return;
    }
    setDuplicateError(null);

    // Remove empty values
    const cleanedValues = removeEmptyValues(values);

    console.log("cleanedValues", cleanedValues);

    try {
      // Fetch the latest index IDs before submission
      const { data: fetchedLatestIndexIds } = await refetchLatestIndexIds();

      if (!fetchedLatestIndexIds) {
        message.error("Unable to generate index IDs. Please try again.");
        return;
      }

      // Generate new index IDs for each field that requires one
      const newIndexIds = generateNewIndexIds(
        fetchedLatestIndexIds,
        selectedDictionaryName,
        isNewVocab,
        cleanedValues // Pass the cleaned form values
      );

      // Merge the new index IDs with the cleaned values
      const mergedValues = { ...cleanedValues, ...newIndexIds };

      // Prepare data, excluding dictionaryId when editing
      const preparedData: IVocab = prepareData(mergedValues, !isNewVocab);

      // Ensure _id is included for accent if it exists
      if (preparedData.accent && vocabData?.accents?._id) {
        preparedData.accent.id = vocabData.accents._id;
      }

      // Final check to remove any remaining undefined values
      const finalData = Object.fromEntries(
        Object.entries(preparedData).filter(([_, v]) => v != null)
      );

      // Use FormData
      const formData = new FormData();

      // Append all the existing form data
      Object.entries(finalData).forEach(([key, value]) => {
        if (key !== "sound") {
          // If the value is an array or object, convert it to a JSON string
          if (Array.isArray(value) || typeof value === "object") {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value as string);
          }
        }
      });

      // Append the sound file if it exists
      if (soundFile && soundFile instanceof File) {
        formData.append("sound", soundFile, soundFile.name);
      } else if (
        soundFile &&
        "originFileObj" in soundFile &&
        soundFile.originFileObj instanceof File
      ) {
        formData.append(
          "sound",
          soundFile.originFileObj,
          soundFile.originFileObj.name
        );
      }

      if (isNewVocab) {
        createMutation.mutate(formData);
      } else {
        if (!id) {
          message.error("ID is required for editing");
          return;
        }
        editMutation.mutate(formData);
      }
    } catch (error) {
      console.error("Error fetching latest index IDs:", error);
      message.error("Failed to prepare submission. Please try again.");
    }
  };

  const onError = (errors: FieldErrors<IVocab>) => {
    console.log("Form Validation Errors:", errors);
    message.error(
      "Form submission failed due to validation errors. Check the console for details."
    );
  };

  const handleFormSubmit = async (e: React.BaseSyntheticEvent) => {
    // Log form values regardless of validation
    const formValues = getValues();
    console.log("Form values (including invalid data):", formValues);

    if (Object.keys(listDelete).length > 0) {
      await Promise.all(
        Object.keys(listDelete).map((item, idx) =>
          handleRemoveOnBackend(item, Object.values(listDelete)[idx] as any, 0)
        )
      );
    }

    // Attempt to validate and submit
    handleSubmit(onSubmit, onError)(e);
  };

  // Add useEffect to clear the error when form values change
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (duplicateError) {
        setDuplicateError(null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, duplicateError]);

  if ((!isNewVocab && isVocabLoading) || isDictionaryLoading) {
    return (
      <div style={{ maxWidth: 1000, margin: "0 auto", padding: "24px" }}>
        <Skeleton.Input style={{ width: 200, marginBottom: 16 }} active />
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <Skeleton active paragraph={{ rows: 6 }} />
            <Skeleton.Input
              style={{ width: "100%", height: 32, marginTop: 16 }}
              active
            />
            <Skeleton.Input
              style={{ width: "100%", height: 32, marginTop: 16 }}
              active
            />
          </Col>
          {!isMobile && (
            <Col xs={24} md={12}>
              <Skeleton active paragraph={{ rows: 6 }} />
              <Skeleton.Input
                style={{
                  width: "100%",
                  height: 32,
                  marginTop: 16,
                }}
                active
              />
              <Skeleton.Input
                style={{
                  width: "100%",
                  height: 32,
                  marginTop: 16,
                }}
                active
              />
            </Col>
          )}
        </Row>
        {isMobile && (
          <div style={{ marginTop: 24 }}>
            <Skeleton active paragraph={{ rows: 6 }} />
            <Skeleton.Input
              style={{ width: "100%", height: 32, marginTop: 16 }}
              active
            />
            <Skeleton.Input
              style={{ width: "100%", height: 32, marginTop: 16 }}
              active
            />
          </div>
        )}
      </div>
    );
  }

  const renderHeading = ({ title }: { title: string }) => {
    return (
      <>
        <Text
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#7E5435",
          }}
        >
          {title}
        </Text>
        <div
          style={{
            height: 8,
          }}
        />
      </>
    );
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={handleFormSubmit}
      style={{ maxWidth: 1000, margin: "0 auto" }}
      autoComplete="off"
    >
      <Title level={4}>{isNewVocab ? "Create New Vocab" : "Edit Vocab"}</Title>

      {!isNewVocab && vocabData?.wordIndexId && (
        <Text strong>
          Index ID: <Text code>{vocabData.wordIndexId}</Text>
        </Text>
      )}
      <div style={{ height: 8 }} />
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          {renderHeading({ title: "詞目" })}
          {/* Dictionary */}
          <Form.Item label="來源" required={isNewVocab}>
            <Controller
              name="dictionaryId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!isNewVocab}
                  onChange={(value) => {
                    field.onChange(value);
                    const dictionary = dictionaryData?.results.find(
                      (dict: IDictionary) => dict.id === value
                    );
                    if (dictionary) {
                      setSelectedDictionaryName(dictionary.name);
                    }
                  }}
                >
                  {dictionaryData?.results.map((dict: IDictionary) => (
                    <Option key={dict.id} value={dict.id}>
                      {dict.name}
                    </Option>
                  ))}
                </Select>
              )}
            />
            {errors.dictionaryId && <span>{errors.dictionaryId.message}</span>}
          </Form.Item>
          <Form.Item label="漢字" required>
            <Controller
              name="hanji"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            {errors.hanji && <span>{errors.hanji.message}</span>}
          </Form.Item>

          <Form.Item label="羅馬字" required>
            <Controller
              name="roman"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            {errors.roman && <span>{errors.roman.message}</span>}
          </Form.Item>

          <Form.Item label="分類">
            <Controller
              name="category"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            {errors.category && <span>{errors.category.message}</span>}
          </Form.Item>

          <Form.Item label="子分類">
            <Controller
              name="subCategory"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            {errors.subCategory && <span>{errors.subCategory.message}</span>}
          </Form.Item>

          <Form.Item label="詞源">
            <Controller
              name="etymology"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
            {errors.etymology && <span>{errors.etymology.message}</span>}
          </Form.Item>
          {/* Alternative Character */}
          <Form.List name="alternativeCharacter">
            {() => (
              <>
                <Text>Alternative Character</Text>
                <div style={{ height: 8 }} />
                {alternativeCharacterFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom:
                        index === alternativeCharacterFields.length - 1
                          ? 16
                          : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item
                          required={false}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Controller
                            name={`alternativeCharacter.${index}.alternativeCharacterHanji`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(
                              `alternativeCharacter.${index}`
                            ) as any;
                            if (fieldValue && fieldValue.id) {
                              setListDelete({
                                ...listDelete,
                                [fieldValue.id]: "alternativeCharacter",
                              });

                              removeAlternativeCharacter(index);
                            } else {
                              removeAlternativeCharacter(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < alternativeCharacterFields.length - 1 && (
                      <div style={{ height: 8 }} /> /* Spacing between fields */
                    )}
                  </div>
                ))}
                {/* Spacing before the Add button */}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      appendAlternativeCharacter({
                        alternativeCharacterHanji: "",
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Alternative Character
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Alternative Pronounce */}
          <Form.List name="alternativePronounce">
            {() => (
              <>
                <Text>Alternative Pronounce</Text>
                <div style={{ height: 8 }} />
                {alternativePronounceFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom:
                        index === alternativePronounceFields.length - 1
                          ? 16
                          : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item
                          required={false}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Controller
                            name={`alternativePronounce.${index}.alternativePronounceRoman`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(
                              `alternativePronounce.${index}`
                            ) as any;
                            if (fieldValue && fieldValue.id) {
                              removeAlternativePronounce(index);
                            } else {
                              removeAlternativePronounce(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < alternativePronounceFields.length - 1 && (
                      <div style={{ height: 8 }} />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      appendAlternativePronounce({
                        alternativePronounceRoman: "",
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Alternative Pronounce
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Mix Word */}
          <Form.List name="mixWord">
            {() => (
              <>
                <Text>Mix Word</Text>
                <div style={{ height: 8 }} />
                {mixWordFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom: index === mixWordFields.length - 1 ? 16 : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item required={false} style={{ marginBottom: 0 }}>
                          <Controller
                            name={`mixWord.${index}.mixWordRoman`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(`mixWord.${index}`) as any;
                            if (fieldValue && fieldValue.id) {
                              setListDelete({
                                ...listDelete,
                                [fieldValue.id]: "mixWord",
                              });
                              removeMixWord(index);
                            } else {
                              removeMixWord(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < mixWordFields.length - 1 && (
                      <div style={{ height: 8 }} />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => appendMixWord({ mixWordRoman: "" })}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Mix Word
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Corrupted Pronounce */}
          <Form.List name="corruptedPronounce">
            {() => (
              <>
                <Text>Corrupted Pronounce</Text>
                <div style={{ height: 8 }} />
                {corruptedPronounceFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom:
                        index === corruptedPronounceFields.length - 1 ? 16 : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item
                          required={false}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Controller
                            name={`corruptedPronounce.${index}.corruptedPronounceRoman`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(
                              `corruptedPronounce.${index}`
                            ) as any;
                            if (fieldValue && fieldValue.id) {
                              setListDelete({
                                ...listDelete,
                                [fieldValue.id]: "corruptedPronounce",
                              });
                              removeCorruptedPronounce(index);
                            } else {
                              removeCorruptedPronounce(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < corruptedPronounceFields.length - 1 && (
                      <div style={{ height: 8 }} />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      appendCorruptedPronounce({
                        corruptedPronounceRoman: "",
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Corrupted Pronounce
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Meaning */}
          {renderHeading({ title: "意義" })}
          {meaningFields.map((field, meaningIndex) => (
            <Card
              key={field.id}
              style={{
                marginBottom: 16,
              }}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{`Meaning ${meaningIndex + 1}`}</span>
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      const fieldValue = watch(
                        `meaning.${meaningIndex}`
                      ) as any;
                      if (fieldValue && fieldValue.id) {
                        setListDelete({
                          ...listDelete,
                          [fieldValue.id]: "meaning",
                        });
                        removeMeaning(meaningIndex);
                      } else {
                        removeMeaning(meaningIndex);
                      }
                    }}
                  />
                </div>
              }
            >
              <Form.Item label="詞性">
                <Controller
                  name={`meaning.${meaningIndex}.partOfSpeech`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
              <Form.Item label="中文意義">
                <Controller
                  name={`meaning.${meaningIndex}.meaningChinese`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
              <Form.Item label="英文意義">
                <Controller
                  name={`meaning.${meaningIndex}.meaningEnglish`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>

              <Form.Item label="他加祿語意義">
                <Controller
                  name={`meaning.${meaningIndex}.meaningTagalog`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
              <Form.Item label="印尼語意義">
                <Controller
                  name={`meaning.${meaningIndex}.meaningIndonesia`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
              <Form.Item label="馬來語意義">
                <Controller
                  name={`meaning.${meaningIndex}.meaningMalay`}
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>
              <div style={{ height: "12px" }} />

              {/* Meaning Synonym */}
              <Form.List
                name={`meaning.${meaningIndex}.meaningSynonym`}
                initialValue={getValues(
                  `meaning.${meaningIndex}.meaningSynonym`
                )}
              >
                {(synonymFields, { add, remove }) => (
                  <>
                    <Text>Meaning Synonym</Text>
                    <div style={{ height: 8 }} />
                    {(synonymFields as FieldWithPossibleId[]).map(
                      (synonymField, synonymIndex) => (
                        <div
                          key={synonymField.fieldKey}
                          style={{
                            marginBottom:
                              synonymIndex === synonymFields.length - 1
                                ? 16
                                : 0,
                          }}
                        >
                          <Row gutter={16} align="middle">
                            <Col flex="auto">
                              <Form.Item
                                required={false}
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <Controller
                                  name={`meaning.${meaningIndex}.meaningSynonym.${synonymIndex}.relativeMeaningIndexId`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      showSearch
                                      value={
                                        watch(
                                          `meaning.${meaningIndex}.meaningSynonym.${synonymIndex}.relativeMeaningChinese`
                                        )
                                          ? `${watch(`meaning.${meaningIndex}.meaningSynonym.${synonymIndex}.relativeMeaningChinese`) || ""} (${field.value})`
                                          : field.value
                                      }
                                      placeholder="Search for meaning synonyms"
                                      onSearch={(value) =>
                                        handleSearch(value, "meaningSynonym")
                                      }
                                      onChange={(value) =>
                                        field.onChange(value)
                                      }
                                      filterOption={false}
                                      loading={isMeaningSynonymSearchLoading}
                                      options={meaningSynonymOptions?.results.map(
                                        (option: MeaningOption) => ({
                                          value: option.meaningIndexId,
                                          label: `${option.meaningChinese} (${option.meaningIndexId})`,
                                        })
                                      )}
                                      disabled={!isDictionarySelected}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                type="text"
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  const fieldValue = watch(
                                    `meaning.${meaningIndex}.meaningSynonym.${synonymIndex}`
                                  ) as any;
                                  if (fieldValue && fieldValue.id) {
                                    setListDelete({
                                      ...listDelete,
                                      [fieldValue.id]: "meaningSynonym",
                                    });
                                    remove(synonymIndex);
                                    setValue(
                                      `meaning.${meaningIndex}.meaningSynonym`,
                                      watch(
                                        `meaning.${meaningIndex}.meaningSynonym`
                                      )?.filter(
                                        (item) => item.id !== fieldValue.id
                                      )
                                    );
                                    console.log(
                                      "watch: ",
                                      watch(
                                        `meaning.${meaningIndex}.meaningSynonym`
                                      )
                                    );
                                  } else {
                                    remove(synonymIndex);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          {synonymIndex < synonymFields.length - 1 && (
                            <div style={{ height: 8 }} />
                          )}
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add({})}
                        block
                        icon={<PlusOutlined />}
                        disabled={!isDictionarySelected}
                      >
                        Add Meaning Synonym
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Meaning Antonym */}
              <Form.List
                name={`meaning.${meaningIndex}.meaningAntonym`}
                initialValue={getValues(
                  `meaning.${meaningIndex}.meaningAntonym`
                )}
              >
                {(antonymFields, { add, remove }) => (
                  <>
                    <Text>Meaning Antonym</Text>
                    <div style={{ height: 8 }} />
                    {(antonymFields as FieldWithPossibleId[]).map(
                      (antonymField, antonymIndex) => (
                        <div
                          key={antonymField.fieldKey}
                          style={{
                            marginBottom:
                              antonymIndex === antonymFields.length - 1
                                ? 16
                                : 0,
                          }}
                        >
                          <Row gutter={16} align="middle">
                            <Col flex="auto">
                              <Form.Item
                                required={false}
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <Controller
                                  name={`meaning.${meaningIndex}.meaningAntonym.${antonymIndex}.relativeMeaningIndexId`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      showSearch
                                      value={
                                        watch(
                                          `meaning.${meaningIndex}.meaningAntonym.${antonymIndex}.relativeMeaningChinese`
                                        )
                                          ? `${watch(`meaning.${meaningIndex}.meaningAntonym.${antonymIndex}.relativeMeaningChinese`) || ""} (${field.value})`
                                          : field.value
                                      }
                                      placeholder="Search for meaning antonyms"
                                      onSearch={(value) =>
                                        handleSearch(value, "meaningAntonym")
                                      }
                                      onChange={(value) =>
                                        field.onChange(value)
                                      }
                                      filterOption={false}
                                      loading={isMeaningAntonymSearchLoading}
                                      options={meaningAntonymOptions?.results.map(
                                        (option: MeaningOption) => ({
                                          value: option.meaningIndexId,
                                          label: `${option.meaningChinese} (${option.meaningIndexId})`,
                                        })
                                      )}
                                      disabled={!isDictionarySelected}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                type="text"
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  const fieldValue = watch(
                                    `meaning.${meaningIndex}.meaningAntonym.${antonymIndex}`
                                  ) as any;
                                  if (fieldValue && fieldValue.id) {
                                    setListDelete({
                                      ...listDelete,
                                      [fieldValue.id]: "meaningAntonym",
                                    });
                                    remove(antonymIndex);
                                    setValue(
                                      `meaning.${meaningIndex}.meaningAntonym`,
                                      watch(
                                        `meaning.${meaningIndex}.meaningAntonym`
                                      )?.filter(
                                        (item) => item.id !== fieldValue.id
                                      )
                                    );
                                  } else {
                                    remove(antonymIndex);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          {antonymIndex < antonymFields.length - 1 && (
                            <div style={{ height: 8 }} />
                          )}
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            relativeMeaningIndexId: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                        disabled={!isDictionarySelected}
                      >
                        Add Meaning Antonym
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Meaning Word Synonym */}
              <Form.List
                name={`meaning.${meaningIndex}.meaningWordSynonym`}
                initialValue={getValues(
                  `meaning.${meaningIndex}.meaningWordSynonym`
                )}
              >
                {(wordSynonymFields, { add, remove }) => (
                  <>
                    <Text>Meaning Word Synonym</Text>
                    <div style={{ height: 8 }} />
                    {(wordSynonymFields as FieldWithPossibleId[]).map(
                      (wordSynonymField, wordSynonymIndex) => (
                        <div
                          key={wordSynonymField.fieldKey}
                          style={{
                            marginBottom:
                              wordSynonymIndex === wordSynonymFields.length - 1
                                ? 16
                                : 0,
                          }}
                        >
                          <Row gutter={16} align="middle">
                            <Col flex="auto">
                              <Form.Item
                                required={false}
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <Controller
                                  name={`meaning.${meaningIndex}.meaningWordSynonym.${wordSynonymIndex}.relativeWordIndexId`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      showSearch
                                      value={
                                        watch(
                                          `meaning.${meaningIndex}.meaningWordSynonym.${wordSynonymIndex}.relativeHanji`
                                        )
                                          ? `${watch(`meaning.${meaningIndex}.meaningWordSynonym.${wordSynonymIndex}.relativeHanji`) || ""} (${field.value})`
                                          : field.value
                                      }
                                      placeholder="Search for word synonyms"
                                      onSearch={(value) =>
                                        handleSearch(
                                          value,
                                          "meaningWordSynonym"
                                        )
                                      }
                                      onChange={(value) =>
                                        field.onChange(value)
                                      }
                                      filterOption={false}
                                      loading={
                                        isMeaningWordSynonymSearchLoading
                                      }
                                      options={meaningWordSynonymOptions?.results.map(
                                        (option: WordOption) => ({
                                          value: option.wordIndexId,
                                          label: `${option.hanji} (${option.wordIndexId})`,
                                        })
                                      )}
                                      disabled={!isDictionarySelected}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                type="text"
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  const fieldValue = watch(
                                    `meaning.${meaningIndex}.meaningWordSynonym.${wordSynonymIndex}`
                                  ) as any;
                                  if (fieldValue && fieldValue.id) {
                                    setListDelete({
                                      ...listDelete,
                                      [fieldValue.id]: "meaningWordSynonym",
                                    });
                                    remove(wordSynonymIndex);
                                    setValue(
                                      `meaning.${meaningIndex}.meaningWordSynonym`,
                                      watch(
                                        `meaning.${meaningIndex}.meaningWordSynonym`
                                      )?.filter(
                                        (item) => item.id !== fieldValue.id
                                      )
                                    );
                                  } else {
                                    remove(wordSynonymIndex);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          {wordSynonymIndex < wordSynonymFields.length - 1 && (
                            <div
                              style={{
                                height: 8,
                              }}
                            />
                          )}
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            relativeWordIndexId: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                        disabled={!isDictionarySelected}
                      >
                        Add Meaning Word Synonym
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Meaning Word Antonym */}
              <Form.List
                name={`meaning.${meaningIndex}.meaningWordAntonym`}
                initialValue={getValues(
                  `meaning.${meaningIndex}.meaningWordAntonym`
                )}
              >
                {(wordAntonymFields, { add, remove }) => (
                  <>
                    <Text>Meaning Word Antonym</Text>
                    <div style={{ height: 8 }} />
                    {(wordAntonymFields as FieldWithPossibleId[]).map(
                      (wordAntonymField, wordAntonymIndex) => (
                        <div
                          key={wordAntonymField.fieldKey}
                          style={{
                            marginBottom:
                              wordAntonymIndex === wordAntonymFields.length - 1
                                ? 16
                                : 0,
                          }}
                        >
                          <Row gutter={16} align="middle">
                            <Col flex="auto">
                              <Form.Item
                                required={false}
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <Controller
                                  name={`meaning.${meaningIndex}.meaningWordAntonym.${wordAntonymIndex}.relativeWordIndexId`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      showSearch
                                      value={
                                        watch(
                                          `meaning.${meaningIndex}.meaningWordAntonym.${wordAntonymIndex}.relativeHanji`
                                        )
                                          ? `${watch(`meaning.${meaningIndex}.meaningWordAntonym.${wordAntonymIndex}.relativeHanji`) || ""} (${field.value})`
                                          : field.value
                                      }
                                      placeholder="Search for word antonyms"
                                      onSearch={(value) =>
                                        handleSearch(
                                          value,
                                          "meaningWordAntonym"
                                        )
                                      }
                                      onChange={(value) =>
                                        field.onChange(value)
                                      }
                                      filterOption={false}
                                      loading={
                                        isMeaningWordAntonymSearchLoading
                                      }
                                      options={meaningWordAntonymOptions?.results.map(
                                        (option: WordOption) => ({
                                          value: option.wordIndexId,
                                          label: `${option.hanji} (${option.wordIndexId})`,
                                        })
                                      )}
                                      disabled={!isDictionarySelected}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                type="text"
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  const fieldValue = watch(
                                    `meaning.${meaningIndex}.meaningWordAntonym.${wordAntonymIndex}`
                                  ) as any;
                                  if (fieldValue && fieldValue.id) {
                                    setListDelete({
                                      ...listDelete,
                                      [fieldValue.id]: "meaningWordAntonym",
                                    });
                                    remove(wordAntonymIndex);
                                    setValue(
                                      `meaning.${meaningIndex}.meaningWordAntonym`,
                                      watch(
                                        `meaning.${meaningIndex}.meaningWordAntonym`
                                      )?.filter(
                                        (item) => item.id !== fieldValue.id
                                      )
                                    );
                                  } else {
                                    remove(wordAntonymIndex);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          {wordAntonymIndex < wordAntonymFields.length - 1 && (
                            <div
                              style={{
                                height: 8,
                              }}
                            />
                          )}
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            relativeWordIndexId: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                        disabled={!isDictionarySelected}
                      >
                        Add Meaning Word Antonym
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              {/* Example */}
              <Form.List
                name={`meaning.${meaningIndex}.example`}
                initialValue={getValues(`meaning.${meaningIndex}.example`)}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Text>Example</Text>
                    <div style={{ height: 8 }} />
                    {fields.map((exampleField, exampleIndex) => (
                      <Card
                        key={exampleField.key}
                        style={{ marginBottom: 16 }}
                        title={`Example ${exampleIndex + 1}`}
                        extra={
                          <Button
                            onClick={() => {
                              const fieldValue = watch(
                                `meaning.${meaningIndex}.example.${exampleIndex}`
                              ) as any;
                              if (fieldValue && fieldValue.id) {
                                setListDelete({
                                  ...listDelete,
                                  [fieldValue.id]: "example",
                                });
                                remove(exampleIndex);
                                setValue(
                                  `meaning.${meaningIndex}.example`,
                                  watch(
                                    `meaning.${meaningIndex}.example`
                                  )?.filter((item) => item.id !== fieldValue.id)
                                );
                              } else {
                                remove(exampleIndex);
                              }
                            }}
                            icon={<MinusCircleOutlined />}
                          />
                        }
                      >
                        <Form.Item label="範例漢字">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleHanji`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例羅馬字">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleRoman`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例中文">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleChinese`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例英文">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleEnglish`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例馬來文">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleMalay`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例他加祿文">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleTagalog`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                        <Form.Item label="範例印尼文">
                          <Controller
                            name={`meaning.${meaningIndex}.example.${exampleIndex}.exampleIndonesia`}
                            control={control}
                            render={({ field }) => <Input {...field} />}
                          />
                        </Form.Item>
                      </Card>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            exampleHanji: "",
                            exampleRoman: "",
                            exampleChinese: "",
                            exampleEnglish: "",
                            exampleMalay: "",
                            exampleTagalog: "",
                            exampleIndonesia: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Example
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() =>
                appendMeaning({
                  partOfSpeech: "",
                  meaningChinese: "",
                  meaningEnglish: "",
                  meaningHokkien: "",
                  meaningTagalog: "",
                  meaningIndonesia: "",
                  meaningMalay: "",
                  meaningSynonym: [],
                  meaningAntonym: [],
                  meaningWordSynonym: [],
                  meaningWordAntonym: [],
                  example: [],
                })
              }
              block
              icon={<PlusOutlined />}
            >
              Add Meaning
            </Button>
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          {/* Word Synonym */}
          {renderHeading({ title: "詞目-詞目近義" })}
          <Text>Word Synonym</Text>
          <div style={{ height: 8 }} />
          <Form.List name="wordSynonym">
            {() => (
              <>
                {wordSynonymFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom:
                        index === wordSynonymFields.length - 1 ? 16 : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item required={false} style={{ marginBottom: 0 }}>
                          <Tooltip
                            title={!isDictionarySelected ? tooltipTitle : ""}
                          >
                            <Controller
                              name={`wordSynonym.${index}.relativeWordIndexId`}
                              control={control}
                              render={({ field: controllerField }) => (
                                <Select
                                  {...controllerField}
                                  showSearch
                                  value={
                                    watch(`wordSynonym.${index}.relativeHanji`)
                                      ? `${watch(`wordSynonym.${index}.relativeHanji`) || ""} (${controllerField.value})`
                                      : controllerField.value
                                  }
                                  placeholder="Search for synonyms"
                                  onSearch={(value) =>
                                    handleSearch(value, "wordSynonym")
                                  }
                                  onChange={(value) =>
                                    controllerField.onChange(value)
                                  }
                                  filterOption={false}
                                  loading={isWordSynonymSearchLoading}
                                  options={wordSynonymOptions?.results.map(
                                    (option: WordOption) => ({
                                      value: option.wordIndexId,
                                      label: `${option.hanji} (${option.wordIndexId})`,
                                    })
                                  )}
                                  disabled={!isDictionarySelected}
                                />
                              )}
                            />
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(
                              `wordSynonym.${index}`
                            ) as any;
                            if (fieldValue && fieldValue.id) {
                              setListDelete({
                                ...listDelete,
                                [fieldValue.id]: "wordSynonym",
                              });
                              removeWordSynonym(index);
                            } else {
                              removeWordSynonym(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < wordSynonymFields.length - 1 && (
                      <div style={{ height: 8 }} />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Tooltip title={!isDictionarySelected ? tooltipTitle : ""}>
                    <Button
                      type="dashed"
                      onClick={() =>
                        appendWordSynonym({
                          relativeWordIndexId: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                      disabled={!isDictionarySelected}
                    >
                      Add Word Synonym
                    </Button>
                  </Tooltip>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Word Antonym */}
          {renderHeading({ title: "詞目-詞目反義" })}
          <Form.List name="wordAntonym">
            {() => (
              <>
                <Text>Word Antonym</Text>
                <div style={{ height: 8 }} />
                {wordAntonymFields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      marginBottom:
                        index === wordAntonymFields.length - 1 ? 16 : 0,
                    }}
                  >
                    <Row gutter={16} align="middle">
                      <Col flex="auto">
                        <Form.Item required={false} style={{ marginBottom: 0 }}>
                          <Tooltip
                            title={!isDictionarySelected ? tooltipTitle : ""}
                          >
                            <Controller
                              name={`wordAntonym.${index}.relativeWordIndexId`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  showSearch
                                  value={
                                    watch(`wordAntonym.${index}.relativeHanji`)
                                      ? `${watch(`wordAntonym.${index}.relativeHanji`) || ""} (${field.value})`
                                      : field.value
                                  }
                                  placeholder="Search for antonyms"
                                  onSearch={(value) =>
                                    handleSearch(value, "wordAntonym")
                                  }
                                  onChange={(value) => field.onChange(value)}
                                  filterOption={false}
                                  loading={isWordAntonymSearchLoading}
                                  options={wordAntonymOptions?.results.map(
                                    (option: WordOption) => ({
                                      value: option.wordIndexId,
                                      label: `${option.hanji} (${option.wordIndexId})`,
                                    })
                                  )}
                                  disabled={!isDictionarySelected}
                                />
                              )}
                            />
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            const fieldValue = watch(
                              `wordAntonym.${index}`
                            ) as any;
                            if (fieldValue && fieldValue.id) {
                              setListDelete({
                                ...listDelete,
                                [fieldValue.id]: "wordAntonym",
                              });
                              removeWordAntonym(index);
                            } else {
                              removeWordAntonym(index);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {index < wordAntonymFields.length - 1 && (
                      <div style={{ height: 8 }} />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Tooltip title={!isDictionarySelected ? tooltipTitle : ""}>
                    <Button
                      type="dashed"
                      onClick={() =>
                        appendWordAntonym({
                          relativeWordIndexId: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                      disabled={!isDictionarySelected}
                    >
                      Add Word Antonym
                    </Button>
                  </Tooltip>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Accent */}
          {renderHeading({ title: "語音差異" })}
          <Card>
            <Form.Item label="Lugang">
              <Controller
                name="accent.accentLugang"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Sanxia">
              <Controller
                name="accent.accentSanxia"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Taipei">
              <Controller
                name="accent.accentTaipei"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Yilan">
              <Controller
                name="accent.accentYilan"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Tainan">
              <Controller
                name="accent.accentTainan"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Gaoxiong">
              <Controller
                name="accent.accentGaoxiong"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Jinmen">
              <Controller
                name="accent.accentJinmen"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Magong">
              <Controller
                name="accent.accentMagong"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Xinchu">
              <Controller
                name="accent.accentXinchu"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
            <Form.Item label="Taichung">
              <Controller
                name="accent.accentTaichung"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Card>
          <div style={{ height: 16 }} />
          {renderHeading({ title: "上傳發音" })}
          <Form.Item>
            <Controller
              name="sound"
              control={control}
              render={({ field }) => (
                <>
                  <Dragger
                    beforeUpload={handleSoundUpload}
                    onRemove={handleSoundRemove}
                    fileList={soundFile ? [soundFile] : []}
                    multiple={false}
                    accept="audio/webm,video/webm"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p>Click to upload or drag and drop a WebM file</p>
                    <p className="ant-upload-hint">
                      Only WebM format is accepted (max 50MB)
                    </p>
                  </Dragger>
                  {fileInfo && (
                    <div style={{ marginTop: "8px" }}>
                      <Text>File Size: {fileInfo.size}</Text>
                      <br />
                      <Text>File Type: {fileInfo.type}</Text>
                    </div>
                  )}
                </>
              )}
            />
          </Form.Item>

          {currentSoundUrl && (
            <Form.Item>
              {" "}
              <audio
                ref={audioRef}
                src={currentSoundUrl}
                style={{ display: "none" }}
              />
              <Space>
                <Button
                  onClick={handlePlaySound}
                  icon={
                    isPlaying ? (
                      <StopIcon style={{ width: "12px", height: "12px" }} />
                    ) : (
                      <PlayCircleOutlined />
                    )
                  }
                >
                  {isPlaying ? "Stop" : "Play"} Current Sound
                </Button>
                <Button
                  onClick={handleDownloadSound}
                  icon={<DownloadOutlined />}
                  loading={isDownloading}
                >
                  Download
                </Button>
              </Space>
            </Form.Item>
          )}
        </Col>
      </Row>
      {duplicateError && duplicateError.length > 0 && (
        <Alert
          message="Duplicate Entries Detected"
          description={
            <div>
              <p>The following duplicate entries were found:</p>
              <ul>
                {duplicateError.map((error, index) => (
                  <li key={index}>
                    Duplicate {error.type} detected in{" "}
                    <strong>{error.field}</strong>:
                    <br />
                    {error.type === "Word Synonym" ||
                    error.type === "Word Antonym" ? (
                      <span>
                        Word with index ID <strong>{error.value}</strong> is
                        already used.
                      </span>
                    ) : error.type.startsWith("Meaning") ? (
                      <span>
                        Meaning or word with index ID{" "}
                        <strong>{error.value}</strong> is already used in this
                        section.
                      </span>
                    ) : (
                      <span>
                        Entry <strong>{error.value}</strong> is duplicated.
                      </span>
                    )}
                  </li>
                ))}
              </ul>
              <p>
                Please remove or change the duplicate entries before submitting.
                Each entry should be unique within its category.
              </p>
            </div>
          }
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={createMutation.isPending || editMutation.isPending}
          >
            {isNewVocab ? "Create" : "Update"}
          </Button>
          <Button onClick={() => navigate("/vocab")}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default VocabAddEdit;
