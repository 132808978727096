import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import { apiRequest } from "../../../utils/API";
import {
  IAntDProTableRequest,
  IAPIResponseGetDictionaries,
  IDictionary,
} from "../type/DictionaryManagementType";

//* Get all admin and moderator
export const getDictionaryList = async ({
  params = {},
}: {
  params: Record<string, any>;
}) => {
  const queryParams = new URLSearchParams();

  if (params.current) queryParams.append("page", params.current.toString());
  if (params.pageSize) queryParams.append("limit", params.pageSize.toString());
  if (params.name) queryParams.append("name", params.name.toString());
  if (params.author) queryParams.append("author", params.author.toString());

  const response = await apiRequest<IAPIResponseGetDictionaries>(
    "GET",
    `/dictionaries?${queryParams.toString()}`
  );

  return response.data;
};

export const createDictionary = async (data: IDictionary) => {
  return await apiRequest<IDictionary>("POST", "/dictionaries", {
    name: data.name,
    author: data.author,
    totalVocab: data.totalVocab,
    remark: data.remark,
  });
};

export const getDictionary = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetDictionaries>(
    "GET",
    `/dictionaries/${id}`
  );
  return response.data;
};

export const editDictionary = async (data: IDictionary) => {
  return await apiRequest<IDictionary>("PATCH", `/dictionaries/${data.id}`, {
    name: data.name,
    author: data.author,
    totalVocab: data.totalVocab,
    remark: data.remark,
  });
};

export const deleteDictionary = async (id: string) => {
  const response = await apiRequest<void>(
    "PATCH",
    `/dictionaries/${id}/archive`
  );

  console.log(response);

  return response;
};

export interface ImportError {
  code: number;
  message: string;
  details: Array<{
    sheet: string;
    row: number;
    column: string;
    data: number | string;
    message: string;
  }>;
  stack?: string;
}

export async function importDictionary(
  file: File,
  onUploadProgress: (progress: number) => void,
  onUploadComplete: () => void,
  isUpdate: boolean = false
): Promise<() => void> {
  const formData = new FormData();
  formData.append("file", file);

  const source: CancelTokenSource = axios.CancelToken.source();

  try {
    await apiRequest<void>(
      isUpdate ? "PATCH" : "POST",
      "/dictionaries/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: source.token,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onUploadProgress(progress);
            if (progress === 100) {
              onUploadComplete();
            }
          }
        },
      }
    );
  } catch (error: any) {
    console.error("API request error:", error);
    throw error;
  }

  // Return a function to cancel the request
  return () => source.cancel("Operation canceled by the user.");
}

export const exportDictionary = async (id: string): Promise<{ data: Blob, filename: string }> => {
  const response = await apiRequest<Blob>(
    "GET",
    `/dictionaries/${id}/exports`,
    {},
    {
      responseType: "blob",
    }
  );
  
  let filename = `dictionary_${id}.xlsx`; // Default filename

  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    // RFC 6266 compliant parsing
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  
  return { data: response.data, filename };
};
