import React, { useState, useEffect, useCallback } from "react";
import { Upload, Progress, message, Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { importDictionary, ImportError } from "../api/DictionaryAPI";
import ExcelImportErrorDisplay from "./ExcelImportErrorDisplay";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;
const { Text } = Typography;

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

interface ImportDictionaryProps {
  isUpdate: boolean;
}

const ImportDictionary: React.FC<ImportDictionaryProps> = ({ isUpdate }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [importError, setImportError] = useState<ImportError | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [cancelUpload, setCancelUpload] = useState<(() => void) | null>(null);
  const navigate = useNavigate();

  const importMutation = useMutation<void, ImportError, File>({
    mutationFn: (file: File) => {
      setIsUploading(true);
      return importDictionary(
        file,
        (progress: number) => {
          setUploadProgress(progress);
        },
        () => {
          setIsProcessing(true);
        },
        isUpdate
      ).then((cancelFn) => {
        setCancelUpload(() => cancelFn);
        return new Promise<void>((resolve) => {
          // This promise will be resolved when the upload is complete
          const checkProcessing = setInterval(() => {
            if (!isProcessing) {
              clearInterval(checkProcessing);
              resolve();
            }
          }, 1000);
        });
      });
    },
    onSuccess: () => {
      setIsUploading(false);
      setIsProcessing(false);
      setCancelUpload(null);
      message.success(
        isUpdate
          ? "Dictionary updated successfully!"
          : "Dictionary imported successfully!"
      );
      navigate("/dictionary");
    },
    onError: (error: ImportError) => {
      setIsUploading(false);
      setIsProcessing(false);
      setCancelUpload(null);
      setImportError(error);
      message.error(
        `Failed to ${isUpdate ? "update" : "import"} dictionary. Please check the error details below.`
      );
    },
  });

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isUploading || isProcessing) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [isUploading, isProcessing]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (cancelUpload) {
        cancelUpload();
      }
    };
  }, [handleBeforeUnload, cancelUpload]);

  const handleImport = (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error(
        "File size exceeds 50MB limit. Please upload a smaller file."
      );
      return false;
    }

    setUploadProgress(0);
    setImportError(null);
    importMutation.mutate(file);
    return false; // Prevent default upload behavior
  };

  return (
    <div className="p-4 space-y-4">
      {importError && (
        <div className="mb-4">
          <ExcelImportErrorDisplay error={importError} />
        </div>
      )}

      <div className="flex flex-row items-center gap-2">
        <Text>{isUpdate ? "Update" : "Import"} Dictionary: </Text>
        <Text type="danger">*</Text>
      </div>

      <Dragger
        accept=".xlsx"
        beforeUpload={handleImport}
        disabled={importMutation.isPending}
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single .xlsx file (max 50MB)
        </p>
      </Dragger>

      {uploadProgress > 0 && uploadProgress < 100 && (
        <Progress
          percent={uploadProgress}
          status={importError ? "exception" : "active"}
        />
      )}

      {isProcessing && (
        <div className="space-y-4 p-4 bg-gray-100 rounded-lg">
          <div className="flex flex-row items-center justify-center space-x-4">
            {/* Custom pulsing circle animation */}
            <div className="w-8 h-8 bg-blue-500 rounded-full animate-pulse"></div>
            <Text style={{ flex: 1 }}>
              Processing your dictionary. Please do not close this page while
              processing. This may take a few minutes...
            </Text>
          </div>
          {/* Progress bar for visual feedback */}
          <Progress percent={100} status="active" showInfo={false} />
        </div>
      )}
    </div>
  );
};

export default ImportDictionary;
