import { ParamsType } from "@ant-design/pro-components";
import { SortOrder } from "antd/lib/table/interface";
import { z } from "zod";

export const UserSchema = z.object({
  _id: z.string().optional(),
  username: z
    .string()
    .min(5, "Username must be more than 5 characters")
    .max(20, "Username must be less than 20 characters"),
  email: z.string().email("Invalid email address"),
  role: z
    .enum(["user", "moderator", "admin"])
    .refine((val) => ["user", "moderator", "admin"].includes(val), {
      message: "Role must be either 'user', 'moderator', or 'admin'",
    }),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .optional(),
  isSuspended: z.boolean().optional(),
  isEmailVerified: z.boolean().optional(),
  imageURL: z.string().optional(),
  name: z.string().optional(),
  isShowLeaderBoard: z.boolean().optional(),
  updatedAt: z.string().optional(),
});

export type IUser = z.infer<typeof UserSchema>;

export interface IAPIResponseGetUsers {
  results: IUser[];
  page: string;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export interface IAntDProTableRequest {
  params: ParamsType & {
    pageSize?: number | undefined;
    current?: number | undefined;
    keyword?: string | undefined;
  };
  sort: Record<string, SortOrder>;
  filter: Record<string, (string | number)[] | null>;
}
