import React, { useCallback, useEffect, useState, useRef } from "react";
import { ProColumns, ProTable, ActionType } from "@ant-design/pro-components";
import { Button, Typography, message, Popconfirm, Alert, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { deleteAnnouncement, getAnnouncementList } from "./api/AnnouncementAPI";
import { IAnnouncement } from "./type/AnnouncementManagementType";
import { debounce } from "lodash";

const { Title } = Typography;

const AnnouncementManagement: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const actionRef = useRef<ActionType>();
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useState<{
    title?: string;
    description?: string;
    isShowing?: boolean;
    dateRange?: [string, string];
  }>({});

  const { data, isLoading, error } = useQuery({
    queryKey: ["announcementList", tableParams, searchParams],
    queryFn: () =>
      getAnnouncementList({
        params: {
          ...tableParams,
          ...searchParams,
        },
        sort: {},
        filter: {},
      }),
  });

  const deleteMutation = useMutation({
    mutationFn: deleteAnnouncement,
    onSuccess: () => {
      message.success("Announcement deleted successfully!");
      queryClient.invalidateQueries({
        queryKey: ["announcementList"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error("Error deleting announcement:", error);
      message.error("Failed to delete announcement.");
    },
  });

  const handleDelete = (id: string) => {
    deleteMutation.mutate(id);
  };

  const debouncedUpdateSearchParams = useCallback(
    debounce((newParams: typeof searchParams) => {
      setSearchParams(newParams);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSearchParams.cancel();
    };
  }, [debouncedUpdateSearchParams]);

  const columns: ProColumns<IAnnouncement>[] = [
    {
      title: "Announcement Title",
      dataIndex: "title",
      key: "title",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedUpdateSearchParams({
            ...searchParams,
            title: e.target.value,
          }),
      },
    },
    {
      title: "Announcement Details",
      dataIndex: "description",
      key: "description",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedUpdateSearchParams({
            ...searchParams,
            description: e.target.value,
          }),
      },
    },
    {
      title: "Status",
      dataIndex: "isShowing",
      key: "isShowing",
      valueType: "select",
      valueEnum: {
        true: { text: "Active", status: "Success" },
        false: { text: "Disabled", status: "Error" },
      },
      render: (_, record) => (
        <Tag color={record.isShowing ? "success" : "error"}>
          {record.isShowing ? "Active" : "Disabled"}
        </Tag>
      ),
      fieldProps: {
        onChange: (value: boolean) =>
          debouncedUpdateSearchParams({ ...searchParams, isShowing: value }),
      },
    },
    {
      title: "Date & Time",
      key: "dateRange",
      dataIndex: "createdAt",
      valueType: "dateRange",
      render: (_, record) => {
        const date = new Date(record.createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      },
      fieldProps: {
        onChange: (dates: [string, string]) =>
          debouncedUpdateSearchParams({ ...searchParams, dateRange: dates }),
      },
    },
    {
      key: "action",
      title: "Action",
      search: false,
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            icon={<FaEdit />}
            onClick={() => navigate(`/announcement/${record._id}`)}
          />
          <Popconfirm
            title='Are you sure to delete this announcement?'
            onConfirm={() => record._id && handleDelete(record._id)}
            okText='Yes'
            cancelText='No'
          >
            <Button icon={<FaTrash />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  if (error) {
    return (
      <Alert
        message='Error loading announcements'
        description={(error as Error).message}
        type='error'
      />
    );
  }

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>Announcement Management</Title>
        <Button
          type='primary'
          onClick={() => navigate("/announcement/new")}
          icon={<FaPlus />}
        >
          Create
        </Button>
      </div>
      <ProTable<IAnnouncement>
        actionRef={actionRef}
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        rowKey='id'
        pagination={{
          total: data?.totalResults,
          ...tableParams,
          onChange: (page, pageSize) => {
            setTableParams({
              current: page,
              pageSize,
            });
          },
        }}
        search={{
          labelWidth: "auto",
        }}
        options={false}
        onSubmit={(params) => {
          setTableParams({
            current: 1,
            pageSize: tableParams.pageSize,
          });
          setSearchParams({
            title: params.title as string | undefined,
            description: params.description as string | undefined,
            isShowing: params.isShowing as boolean | undefined,
            dateRange: params.dateRange as [string, string] | undefined,
          });
        }}
        onReset={() => {
          setTableParams({
            current: 1,
            pageSize: 10,
          });
          setSearchParams({});
          actionRef.current?.reload();
        }}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};

export default AnnouncementManagement;
