import React, { useState, useEffect } from "react";
import { Table, Typography, Alert, Button, Modal } from "antd";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Text, Paragraph } = Typography;

interface ErrorDetail {
  sheet: string;
  row: number;
  column: string;
  data: number | string;
  message: string;
}

export interface ImportError {
  code: number;
  message: string;
  details: ErrorDetail[];
  stack?: string;
}

interface ExcelImportErrorProps {
  error: ImportError;
}

const ExcelImportErrorDisplay: React.FC<ExcelImportErrorProps> = ({
  error,
}) => {
  const [isStackModalVisible, setIsStackModalVisible] = useState(false);
  const [errorDetails, setErrorDetails] = useState<ErrorDetail[]>([]);

  useEffect(() => {
    console.log("Full error object:", error);
    if (error && Array.isArray(error.details)) {
      console.log("Error details:", error.details);
      setErrorDetails(error.details);
    } else {
      console.log("No error details found or details is not an array");
      setErrorDetails([]);
    }
  }, [error]);

  const columns = [
    {
      title: "Sheet",
      dataIndex: "sheet",
      key: "sheet",
    },
    {
      title: "Row",
      dataIndex: "row",
      key: "row",
    },
    {
      title: "Column",
      dataIndex: "column",
      key: "column",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];

  return (
    <div className='p-4 space-y-4 border border-red-500 rounded-md bg-red-50'>
      <Alert
        message={<Text strong>Excel Import Error (Code: {error.code})</Text>}
        description={<Text>{error.message}</Text>}
        type='error'
        showIcon
        icon={<ExclamationCircleOutlined />}
      />
      {errorDetails.length > 0 ? (
        <>
          <div
            style={{
              height: "24px",
            }}
          />
          <Paragraph>
            <Text strong>Total errors: {errorDetails.length}</Text>
          </Paragraph>

          <Table
            columns={columns}
            dataSource={errorDetails.map((detail, index) => ({
              ...detail,
              key: index,
            }))}
            size='small'
            pagination={{ pageSize: 10 }}
            scroll={{ x: true }}
          />
        </>
      ) : (
        <Paragraph>
          <Text>No detailed error information available.</Text>
        </Paragraph>
      )}
    </div>
  );
};

export default ExcelImportErrorDisplay;
