import React from "react";
import { NavLink } from "react-router-dom";
import { Divider, Menu } from "antd";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  deleteUserSession,
  removeUserSession,
  isAdmin,
} from "../../../utils/AuthService";

const SidebarMenu = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    deleteUserSession();
    removeUserSession();
    await new Promise((resolve) => setTimeout(resolve, 100));
    window.location.reload();
    navigate("/login");
  };

  const menuItems = [
    {
      key: "vocab",
      label: <NavLink to='/vocab'>Vocab</NavLink>,
      title: "Vocab",
      icon: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.33317 1.5127V4.26639C9.33317 4.63976 9.33317 4.82644 9.40583 4.96905C9.46975 5.09449 9.57173 5.19648 9.69718 5.26039C9.83978 5.33306 10.0265 5.33306 10.3998 5.33306H13.1535M9.33317 11.333H5.33317M10.6665 8.66634H5.33317M13.3332 6.65849V11.4663C13.3332 12.5864 13.3332 13.1465 13.1152 13.5743C12.9234 13.9506 12.6175 14.2566 12.2412 14.4484C11.8133 14.6663 11.2533 14.6663 10.1332 14.6663H5.8665C4.7464 14.6663 4.18635 14.6663 3.75852 14.4484C3.3822 14.2566 3.07624 13.9506 2.88449 13.5743C2.6665 13.1465 2.6665 12.5864 2.6665 11.4663V4.53301C2.6665 3.4129 2.6665 2.85285 2.88449 2.42503C3.07624 2.0487 3.3822 1.74274 3.75852 1.55099C4.18635 1.33301 4.7464 1.33301 5.8665 1.33301H8.00769C8.49687 1.33301 8.74146 1.33301 8.97163 1.38827C9.17571 1.43726 9.3708 1.51807 9.54974 1.62773C9.75157 1.75141 9.92453 1.92436 10.2704 2.27027L12.3959 4.39575C12.7418 4.74165 12.9148 4.9146 13.0385 5.11644C13.1481 5.29538 13.2289 5.49047 13.2779 5.69454C13.3332 5.92472 13.3332 6.16931 13.3332 6.65849Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      key: "dictionary",
      label: <NavLink to='/dictionary'>Dictionary</NavLink>,
      title: "Dictionary",
      icon: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.00016 14L7.93346 13.8999C7.47037 13.2053 7.23882 12.858 6.9329 12.6065C6.66207 12.3839 6.35001 12.2169 6.01457 12.1151C5.63566 12 5.21823 12 4.38338 12H3.46683C2.72009 12 2.34672 12 2.06151 11.8547C1.81063 11.7268 1.60665 11.5229 1.47882 11.272C1.3335 10.9868 1.3335 10.6134 1.3335 9.86667V4.13333C1.3335 3.3866 1.3335 3.01323 1.47882 2.72801C1.60665 2.47713 1.81063 2.27316 2.06151 2.14532C2.34672 2 2.72009 2 3.46683 2H3.7335C5.22697 2 5.97371 2 6.54414 2.29065C7.0459 2.54631 7.45385 2.95426 7.70951 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667M8.00016 14V6.26667M8.00016 14L8.06687 13.8999C8.52996 13.2053 8.76151 12.858 9.06743 12.6065C9.33826 12.3839 9.65032 12.2169 9.98576 12.1151C10.3647 12 10.7821 12 11.6169 12H12.5335C13.2802 12 13.6536 12 13.9388 11.8547C14.1897 11.7268 14.3937 11.5229 14.5215 11.272C14.6668 10.9868 14.6668 10.6134 14.6668 9.86667V4.13333C14.6668 3.3866 14.6668 3.01323 14.5215 2.72801C14.3937 2.47713 14.1897 2.27316 13.9388 2.14532C13.6536 2 13.2802 2 12.5335 2H12.2668C10.7734 2 10.0266 2 9.45619 2.29065C8.95442 2.54631 8.54647 2.95426 8.29081 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      key: "suggestion",
      label: <NavLink to='/suggestion'>User Suggestions</NavLink>,
      title: "User Suggestions",
      icon: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6.00016 10.3333H5.00017C4.06979 10.3333 3.6046 10.3333 3.22607 10.4482C2.3738 10.7067 1.70686 11.3736 1.44832 12.2259C1.3335 12.6044 1.3335 13.0696 1.3335 14M9.66683 5C9.66683 6.65685 8.32368 8 6.66683 8C5.00998 8 3.66683 6.65685 3.66683 5C3.66683 3.34315 5.00998 2 6.66683 2C8.32368 2 9.66683 3.34315 9.66683 5ZM7.3335 14L9.40107 13.4093C9.50008 13.381 9.54958 13.3668 9.59575 13.3456C9.63675 13.3268 9.67573 13.3039 9.7121 13.2772C9.75306 13.2471 9.78946 13.2107 9.86227 13.1379L14.1669 8.83336C14.6271 8.37311 14.6271 7.62689 14.1669 7.16665C13.7066 6.70642 12.9604 6.70642 12.5002 7.16666L8.19561 11.4712C8.1228 11.544 8.08639 11.5804 8.05632 11.6214C8.02962 11.6578 8.00669 11.6967 7.98786 11.7377C7.96666 11.7839 7.95252 11.8334 7.92423 11.9324L7.3335 14Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      key: "comment",
      label: <NavLink to='/comment'>Comment</NavLink>,
      title: "Comments",
      icon: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M4.66667 5.66667H8M4.66667 8H10M6.45583 12H10.8C11.9201 12 12.4802 12 12.908 11.782C13.2843 11.5903 13.5903 11.2843 13.782 10.908C14 10.4802 14 9.9201 14 8.8V5.2C14 4.07989 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V13.557C2 13.9122 2 14.0898 2.07282 14.1811C2.13615 14.2604 2.23218 14.3066 2.33369 14.3065C2.45042 14.3063 2.58911 14.1954 2.8665 13.9735L4.45681 12.7012C4.78168 12.4413 4.94411 12.3114 5.12499 12.219C5.28547 12.137 5.45628 12.0771 5.63281 12.0408C5.83178 12 6.0398 12 6.45583 12Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      key: "announcement",
      label: <NavLink to='/announcement'>Announcement</NavLink>,
      title: "Announcement",
      icon: (
        <svg
          width='16'
          height='14'
          viewBox='0 0 16 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.6668 4.33425V7.00092M6.83349 2.66758H4.5335C3.41339 2.66758 2.85334 2.66758 2.42551 2.88557C2.04919 3.07732 1.74323 3.38328 1.55148 3.7596C1.3335 4.18743 1.3335 4.74748 1.3335 5.86759L1.3335 6.66758C1.3335 7.28884 1.3335 7.59947 1.43499 7.8445C1.57032 8.1712 1.82988 8.43076 2.15658 8.56609C2.40161 8.66758 2.71224 8.66758 3.3335 8.66758V11.5009C3.3335 11.6557 3.3335 11.7331 3.33992 11.7983C3.40227 12.4313 3.90311 12.9321 4.53614 12.9945C4.60133 13.0009 4.67872 13.0009 4.8335 13.0009C4.98828 13.0009 5.06567 13.0009 5.13085 12.9945C5.76389 12.9321 6.26473 12.4313 6.32708 11.7983C6.3335 11.7331 6.3335 11.6557 6.3335 11.5009V8.66758H6.8335C8.01112 8.66758 9.45166 9.29884 10.563 9.90468C11.2114 10.2581 11.5356 10.4348 11.7479 10.4088C11.9448 10.3847 12.0936 10.2963 12.209 10.135C12.3335 9.96102 12.3335 9.61292 12.3335 8.91673V2.41844C12.3335 1.72225 12.3335 1.37415 12.209 1.20018C12.0936 1.03885 11.9448 0.950451 11.7479 0.926337C11.5356 0.900332 11.2114 1.07705 10.563 1.43049C9.45166 2.03633 8.01111 2.66758 6.83349 2.66758Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
  ];

  if (isAdmin()) {
    menuItems.push({
      key: "userManagement",
      label: <NavLink to='/users'>Users</NavLink>,
      title: "Users",
      icon: (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.6668 14V12.6667C14.6668 11.4241 13.817 10.38 12.6668 10.084M10.3335 2.19384C11.3108 2.58943 12.0002 3.54754 12.0002 4.66667C12.0002 5.78579 11.3108 6.7439 10.3335 7.13949M11.3335 14C11.3335 12.7575 11.3335 12.1362 11.1305 11.6462C10.8599 10.9928 10.3407 10.4736 9.68732 10.203C9.19726 10 8.57601 10 7.3335 10H5.3335C4.09099 10 3.46973 10 2.97967 10.203C2.32627 10.4736 1.80713 10.9928 1.53648 11.6462C1.3335 12.1362 1.3335 12.7575 1.3335 14M9.00016 4.66667C9.00016 6.13943 7.80626 7.33333 6.3335 7.33333C4.86074 7.33333 3.66683 6.13943 3.66683 4.66667C3.66683 3.19391 4.86074 2 6.3335 2C7.80626 2 9.00016 3.19391 9.00016 4.66667Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    });
  }

  return (
    <>
      <Menu
        mode='inline'
        style={{
          flex: 1,
          background: "#1C212D",
          color: "white",
        }}
        items={menuItems}
      />
      <div style={{ height: "50%" }}></div>
      <Divider
        style={{ borderColor: "rgba(255, 255, 255, 0.2)", margin: "0 16px" }}
      />
      <Menu
        mode='inline'
        style={{
          background: "#1C212D",
          color: "white",
        }}
        items={[
          {
            key: "logout",
            label: "Logout",
            title: "Logout",
            icon: <FaSignOutAlt />,
            onClick: handleLogout,
          },
        ]}
      />
    </>
  );
};

export default SidebarMenu;
