import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, message, Typography, Switch } from "antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CloseOutlined } from "@ant-design/icons";
import {
  createUser,
  editUser,
  getUserDetails,
  getUserList,
  toggleUserSuspension,
} from "../api/UserManagementAPI";
import { UserSchema, IUser } from "../type/UserManagementType";

const { Text } = Typography;

const AdminAddEdit = () => {
  const { id: rawId } = useParams();
  const id = rawId || "new";
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUser>({
    resolver: zodResolver(UserSchema),
    mode: "onSubmit",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["user", id],
    queryFn: () => getUserDetails(id),
    enabled: id !== "new",
  });

  const suspendMutation = useMutation({
    mutationFn: (isSuspended: boolean) => toggleUserSuspension(id, isSuspended),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user", id] });
      message.success(
        `Account ${data?.isSuspended ? "unsuspended" : "suspended"} successfully!`
      );
    },
    onError: (error) => {
      console.error("Error toggling suspension:", error);
      const errorMessage = getErrorMessage(error);
      message.error(`Failed to toggle suspension: ${errorMessage}`);
    },
  });

  // Set form values when data is fetched
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key as keyof IUser, data[key]);
      });
    }
  }, [data, setValue]);

  const onFinish = async (values: IUser) => {
    console.log("Form values:", values);
    try {
      const { password, ...rest } = values;
      const payload = password ? values : rest;

      if (id === "new") {
        await createUser(payload);
        message.success("User created successfully!");
      } else {
        await editUser({ ...payload, _id: id });
        message.success("User updated successfully!");
      }
      navigate("/users");
    } catch (error) {
      console.error("Error saving user:", error);
      const errorMessage = getErrorMessage(error);
      message.error(`Failed to save user: ${errorMessage}`);
    }
  };

  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message;
    if (error && typeof error === "object" && "message" in error) {
      return String(error.message);
    }
    return String(error);
  };

  const onError = (errors: FieldErrors<IUser>) => {
    console.log("Validation errors:", errors);
  };

  const handleSuspendToggle = () => {
    if (data) {
      suspendMutation.mutate(!data.isSuspended);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text strong>{id === "new" ? "Create" : "Edit"} User</Text>
        {id !== "new" && (
          <Button
            onClick={handleSuspendToggle}
            icon={data?.isSuspended ? "✓" : <CloseOutlined />}
          >
            {data?.isSuspended ? "Unsuspend Account" : "Suspend Account"}
          </Button>
        )}
      </div>
      <Form onFinish={handleSubmit(onFinish, onError)} layout='vertical'>
        <Form.Item
          label='Username'
          validateStatus={errors.username ? "error" : ""}
          help={errors.username?.message}
        >
          <Controller
            name='username'
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          label='Email'
          validateStatus={errors.email ? "error" : ""}
          help={errors.email?.message}
        >
          <Controller
            name='email'
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          label='Role'
          validateStatus={errors.role ? "error" : ""}
          help={errors.role?.message}
        >
          <Controller
            name='role'
            control={control}
            render={({ field }) => (
              <Select {...field}>
                <Select.Option value='user'>User</Select.Option>
                <Select.Option value='moderator'>Moderator</Select.Option>
                <Select.Option value='admin'>Admin</Select.Option>
              </Select>
            )}
          />
        </Form.Item>
        <Form.Item
          label='Password'
          validateStatus={errors.password ? "error" : ""}
          help={errors.password?.message}
        >
          <Controller
            name='password'
            control={control}
            render={({ field }) => <Input.Password {...field} />}
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {id === "new" ? "Create" : "Update"}
          </Button>
          <Button onClick={() => navigate("/users")} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminAddEdit;
