import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import CreateDictionary from "./CreateEditDictionary";
import ImportDictionary from "./ImportDictionary";

const { TabPane } = Tabs;

const DictionaryAddEdit = () => {
  const { id: rawId } = useParams();
  const id = rawId || "new";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("edit"); // Always start with the first tab

  const isNewDictionary = id === "new";

  return (
    <div style={{ padding: 20 }}>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane
          tab={isNewDictionary ? "Create Dictionary" : "Edit Dictionary"}
          key='edit'
        >
          <CreateDictionary id={id} navigate={navigate} />
        </TabPane>
        <TabPane
          tab={isNewDictionary ? "Import Dictionary" : "Update Import"}
          key='import'
        >
          <ImportDictionary isUpdate={!isNewDictionary} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DictionaryAddEdit;
