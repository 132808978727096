import React from "react";
import { Card, Form, Input, Button, message, Typography } from "antd";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { setUserSession, getToken } from "../../utils/AuthService";
import { ILogin, LoginSchema } from "./type/loginType";
import { login } from "./api/LoginAPI";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";

const LoginPage = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>({
    resolver: zodResolver(LoginSchema),
  });

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: (loginAcc) => {
      console.log("Login response:", loginAcc);

      if (loginAcc.status === "success") {
        const userDetail = loginAcc.data;
        const setUser = setUserSession({
          token: userDetail.tokens,
          user: userDetail.user,
        });

        console.log("User session set:", setUser);
        console.log("Token after login:", getToken());

        if (setUser === true) {
          message.success("Login successful");
          navigate(0);
        } else {
          message.error("Error setting user session");
        }
      } else {
        message.error(loginAcc.data.message);
      }
    },
    onError: (error: any) => {
      console.error("Login error:", error);
      message.error(error.message || "An unexpected error occurred");
    },
  });

  const onSubmit = (data: ILogin) => {
    console.log("Submitting login data:", data);
    loginMutation.mutate(data);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div style={{ justifyContent: "center" }}>
        <div
          style={{ display: "flex", justifyContent: "center", margin: "20px" }}
        >
          <Logo style={{ width: 150, height: 64 }} />
        </div>
        <h2
          style={{
            textAlign: "center",
            color: "#000",
            marginBottom: 20,
            fontSize: 20,
          }}
        >
          Admin Dashboard
        </h2>
        <Card title='Login' style={{ width: 350 }}>
          <Form
            name='loginForm'
            onFinish={handleSubmit(onSubmit)}
            labelAlign='left'
            colon={false}
          >
            <Form.Item
              name='email'
              hasFeedback
              validateStatus={errors.email ? "error" : ""}
              help={errors.email?.message}
            >
              <Controller
                name='email'
                control={control}
                render={({ field }) => <Input placeholder='Email' {...field} />}
              />
            </Form.Item>
            <Form.Item
              name='password'
              hasFeedback
              validateStatus={errors.password ? "error" : ""}
              help={errors.password?.message}
              style={{ marginBottom: 10 }}
            >
              <Controller
                name='password'
                control={control}
                render={({ field }) => (
                  <Input.Password placeholder='Password' {...field} />
                )}
              />
            </Form.Item>
            <div
              style={{
                height: 20,
              }}
            />

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ width: "100%", marginBottom: 5 }}
                loading={loginMutation.isPending}
              >
                Sign In
              </Button>
              <br />
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
