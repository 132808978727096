import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute, PublicRoute, AdminRoute } from "./utils/RouteHandlers";
import Login from "./pages/login/Login";
import RegisterPage from "./pages/register/Register";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UserManagement from "./pages/user/UserManagement";
import AdminAddEdit from "./pages/user/view/UserAddEdit";
import DictionaryManagement from "./pages/dictionary/DictionaryManagement";
import DictionaryAddEdit from "./pages/dictionary/view/DictionaryAddEdit";
import CommentManagement from "./pages/comment/CommentManagement";
import CommentAddEdit from "./pages/comment/view/CommentAddEdit";
import VocabManagement from "./pages/vocab/VocabManagement";
import VocabAddEdit from "./pages/vocab/view/VocabAddEdit";
import SuggestionManagement from "./pages/suggestion/SuggestionManagement";
import AnnouncementManagement from "./pages/announcement/AnnouncementManagement";
import AnnouncementAddEdit from "./pages/announcement/view/AnnouncementAddEdit";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" Component={Login} />
            <Route path="/" Component={Login} />
            <Route path="/forgot-password" Component={ForgotPassword} />
            <Route path="/register" Component={RegisterPage} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dictionary" element={<DictionaryManagement />} />
            <Route path="/dictionary/:id" element={<DictionaryAddEdit />} />
            <Route path="/dictionary/new" element={<DictionaryAddEdit />} />
            <Route path="/comment" element={<CommentManagement />} />
            <Route path="/comment/:id" element={<CommentAddEdit />} />
            <Route path="/vocab" element={<VocabManagement />} />
            <Route path="/vocab/:id" element={<VocabAddEdit />} />
            <Route path="/vocab/new" element={<VocabAddEdit />} />
            <Route path="/suggestion" element={<SuggestionManagement />} />
            <Route path="/announcement" element={<AnnouncementManagement />} />
            <Route path="/announcement/:id" element={<AnnouncementAddEdit />} />
            <Route path="/announcement/new" element={<AnnouncementAddEdit />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path="/users" element={<UserManagement />} />
            <Route path="/users/new" element={<AdminAddEdit />} />
            <Route path="/users/:id" element={<AdminAddEdit />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
