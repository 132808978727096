import { apiRequest } from "../../../utils/API";
import {
  IAntDProTableRequest,
  IAPIResponse,
  IAnnouncement,
} from "../type/AnnouncementManagementType";

export const getAnnouncementList = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  const queryParams = new URLSearchParams();

  if (sort && Object.keys(sort).length > 0) {
    Object.entries(sort).forEach(([key, value]) => {
      queryParams.append("sortBy", `${key}:${value}`);
    });
  }

  if (params?.pageSize) {
    queryParams.append("limit", params.pageSize.toString());
  }

  if (params?.current) {
    queryParams.append("page", params.current.toString());
  }

  if (params?.title) {
    queryParams.append("title", params.title);
  }

  if (params?.description) {
    queryParams.append("description", params.description);
  }

  if (params?.isShowing !== undefined) {
    queryParams.append("isShowing", params.isShowing.toString());
  }

  if (
    params?.dateRange &&
    Array.isArray(params.dateRange) &&
    params.dateRange.length === 2
  ) {
    queryParams.append("dateRange", params.dateRange[0]);
    queryParams.append("dateRange", params.dateRange[1]);
  }

  const response = await apiRequest<IAPIResponse>(
    "GET",
    `/announcements?${queryParams.toString()}`
  );

  return response.data;
};

export const getAnnouncement = async (id: string) => {
  const response = await apiRequest<IAnnouncement>(
    "GET",
    `/announcements/${id}`
  );
  return response.data;
};

export const createAnnouncement = async (data: {
  title: string;
  description: string;
}) => {
  return await apiRequest<IAnnouncement>("POST", "/announcements", data);
};

export const updateAnnouncement = async (
  id: string,
  data: { title: string; description: string; isShowing: boolean }
) => {
  return await apiRequest<IAnnouncement>("PATCH", `/announcements/${id}`, data);
};

export const deleteAnnouncement = async (id: string) => {
  return await apiRequest<void>("PATCH", `/announcements/${id}/archive`);
};
