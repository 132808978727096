import Cookies from "js-cookie";
import { IUser } from "../pages/user/type/UserManagementType";

type TToken = {
  access: {
    token: string;
    expires: string;
  };
  refresh: {
    token: string;
    expires: string;
  };
};

type TUserSession = {
  rememberme?: boolean; // TODO: Remember Me is from the boilerplate and not used in this project, so it can be removed
  token: TToken;
  user: string;
};

// save the token and user into the cookies and expired in 30 days
export const setUserSession = ({
  rememberme = true,
  token,
  user,
}: TUserSession) => {
  Cookies.set("user", JSON.stringify(user), { expires: 30 });
  console.log("token:", token.access);
  if (rememberme === true) {
    Cookies.set("token", JSON.stringify(token), { expires: 30 });
    Cookies.set("user", JSON.stringify(user), { expires: 30 });
  } else if (rememberme === false) {
    Cookies.set("token", JSON.stringify(token), { expires: 1 });
    Cookies.set("user", JSON.stringify(user), { expires: 1 });
  }
  return true;
};

export const deleteUserSession = () => {
  Cookies.remove("user");
  return true;
};

export const isAdmin = (): boolean => {
  const user = getUser();
  return user?.role === "admin";
};

export const isModerator = (): boolean => {
  const user = getUser();
  return user?.role === "moderator";
};

export const isAuthenticated = (): boolean => {
  return getUser() !== null;
};

// Modify the getUser function to return IUser type
export const getUser = (): IUser | null => {
  const userStr = Cookies.get("user");
  if (userStr) {
    return JSON.parse(userStr);
  }
  return null;
};

interface Token {
  access: {
    token: string;
    expires: string;
  };
  refresh: {
    token: string;
    expires: string;
  };
}

// return the token from the cookies
export const getToken = (): Token | null => {
  const token = Cookies.get("token");
  return token ? JSON.parse(token) : null;
};

// remove the token and user from the cookies
export const removeUserSession = () => {
  Cookies.remove("token");
  Cookies.remove("user");
  Cookies.remove("rememberme");
};

// return the remember me value from the cookies
export const getRememberMe = () => Cookies.get("rememberme") || null;

// update and save the token into the cookies
export const setToken = (token: Token, expires: number) => {
  console.log("strig token: ", JSON.stringify(token));
  Cookies.set("token", JSON.stringify(token), { expires });
};

export const setToast = (isToast: any) => {
  Cookies.set("toast", JSON.stringify(isToast), { expires: 1 });
};

export const getToast = () => Cookies.get("toast") || null;

export const removeToast = () => {
  Cookies.remove("toast");
};
