import { ParamsType } from "@ant-design/pro-components";
import { SortOrder } from "antd/lib/table/interface";
import { z } from "zod";

export const DictionarySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  author: z.string().min(1, { message: "Author is required" }),
  totalVocab: z.number().optional(),
  remark: z.string().min(1, { message: "Remark is required" }),
});

export type IDictionary = z.infer<typeof DictionarySchema>;

export interface IAPIResponseGetDictionaries {
  results: IDictionary[];
  page: string;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export interface IAntDProTableRequest {
  params: ParamsType & {
    pageSize?: number | undefined;
    current?: number | undefined;
    keyword?: string | undefined;
  };
  sort: Record<string, SortOrder>;
  filter: Record<string, (string | number)[] | null>;
}
