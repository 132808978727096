import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, message } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createComment, editComment, getComment } from "../api/CommentAPI";
import { CommentSchema, IComment } from "../type/CommentManagementType";

const CommentAddEdit = () => {
  const { id: rawId } = useParams();
  const id = rawId || "new";
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IComment>({
    resolver: zodResolver(CommentSchema),
    mode: "onSubmit",
  });

  const { data, error } = useQuery({
    queryKey: ["comment", id],
    queryFn: () => getComment(id),
    enabled: id !== "new",
  });

  // Set form values when data is fetched
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key as keyof IComment, data[key]);
      });
    }
  }, [data, setValue]);

  // Show error message if fetching data fails
  useEffect(() => {
    if (error) {
      message.error("Failed to fetch comment data.");
    }
  }, [error]);

  const onFinish = async (values: IComment) => {
    console.log("Form values:", values);
    try {
      if (id === "new") {
        await createComment(values);
        message.success("Comment created successfully!");
      } else {
        await editComment(values);
        message.success("Comment updated successfully!");
      }
      navigate("/comment");
    } catch (error) {
      console.error("Error saving comment:", error);
      message.error("Failed to save comment.");
    }
  };

  const onError = (errors: FieldErrors<IComment>) => {
    console.log("Validation errors:", errors);
  };

  return (
    <div style={{ padding: 20 }}>
      <Form onFinish={handleSubmit(onFinish, onError)} layout='vertical'>
        <Form.Item>
          <Input type='text' placeholder='Comment' />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CommentAddEdit;
