export type IndexType =
  | "word"
  | "wordSynonym"
  | "wordAntonym"
  | "meaning"
  | "meaningSynonym"
  | "meaningAntonym"
  | "meaningWordSynonym"
  | "meaningWordAntonym"
  | "example"
  | "alternativeCharacter"
  | "alternativePronounce"
  | "mixWord"
  | "corruptedPronounce"
  | "accent";

const MODULE_LETTERS: Record<IndexType, string> = {
  word: "W",
  wordSynonym: "WS",
  wordAntonym: "WA",
  meaning: "M",
  meaningSynonym: "MS",
  meaningAntonym: "MA",
  meaningWordSynonym: "MWS",
  meaningWordAntonym: "MWA",
  example: "E",
  alternativeCharacter: "AC",
  alternativePronounce: "AP",
  mixWord: "MW",
  corruptedPronounce: "CP",
  accent: "A",
};

export function generateInitialIndexId(
  dictionaryName: string,
  indexType: IndexType
): string {
  const dictionaryPrefix = dictionaryName
    .split(" ")[0]
    .substring(0, 3)
    .toUpperCase();
  const moduleLetters = MODULE_LETTERS[indexType];
  return `${dictionaryPrefix}${moduleLetters}01`; // Always start with 01
}

export function getNextIndexId(currentId: string | null): string {
  if (!currentId) {
    console.error("No current ID provided. This should not happen.");
    return "";
  }

  console.log("Getting next index ID for:", currentId);
  const prefix = currentId.slice(0, -2); // Get all but the last two characters
  const numericPart = currentId.slice(-2); // Get the last two characters
  const nextNum = parseInt(numericPart, 10) + 1;
  let nextId: string;

  if (nextNum > 99) {
    // If we're transitioning to a three-digit number
    nextId = `${prefix}${nextNum}`;
  } else {
    // If we're still in two-digit range
    nextId = `${prefix}${String(nextNum).padStart(2, "0")}`;
  }

  console.log("Next index ID:", nextId);
  return nextId;
}
