import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProTable, ProColumns, ParamsType } from "@ant-design/pro-components";
import { Button, Typography, message } from "antd";
import { FaEye } from "react-icons/fa";
import { TablePaginationConfig } from "antd/es/table";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { IComment, QueryParams } from "../comment/type/CommentManagementType";
import { getCommentList, updateIsShowComment } from "../comment/api/CommentAPI";
import { getDictionaryList } from "../dictionary/api/DictionaryAPI";
import { IDictionary } from "../dictionary/type/DictionaryManagementType";
import { debounce } from "lodash";

const { Text, Title } = Typography;

const CommentManagement: React.FC = () => {
  const dictionaryHostUrl = process.env.REACT_APP_DICTIONARY_HOST_URL;
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
    sortBy: "",
  });

  const [searchParams, setSearchParams] = useState<{
    dictionary?: string;
    hanji?: string;
    dateRange?: [string, string];
  }>({});

  const { data, isLoading } = useQuery({
    queryKey: ["comments", queryParams, searchParams],
    queryFn: () => getCommentList({ ...queryParams, ...searchParams }),
  });

  const { data: dictionaryData } = useQuery({
    queryKey: ["dictionaries"],
    queryFn: () => getDictionaryList({ params: {} }),
  });

  const updateCommentMutation = useMutation({
    mutationFn: ({
      id,
      isShowComment,
    }: {
      id: string;
      isShowComment: boolean;
    }) => updateIsShowComment(id, isShowComment),
    onSuccess: () => {
      message.success("Comment updated successfully");
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      message.error("Failed to update the comment");
    },
  });

  const debouncedSetSearchParams = useCallback(
    debounce((newParams: Partial<typeof searchParams>) => {
      setSearchParams((prev) => ({ ...prev, ...newParams }));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchParams.cancel();
    };
  }, [debouncedSetSearchParams]);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IComment> | SorterResult<IComment>[]
    ) => {
      const sortString = Array.isArray(sorter)
        ? sorter
            .map((s) => `${s.field}:${s.order === "ascend" ? "asc" : "desc"}`)
            .join(",")
        : sorter.field && sorter.order
          ? `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`
          : "";

      let dateRange: [string, string] | undefined;
      if (Array.isArray(filters.dateRange) && filters.dateRange.length === 2) {
        dateRange = filters.dateRange as [string, string];
      }

      setQueryParams((prev) => ({
        ...prev,
        page: pagination.current || 1,
        limit: pagination.pageSize || 10,
        sortBy: sortString,
      }));

      debouncedSetSearchParams({
        dictionary: filters.dictionary?.[0] as string | undefined,
        hanji: filters.hanji?.[0] as string | undefined,
        dateRange: dateRange,
      });
    },
    [debouncedSetSearchParams]
  );

  const columns: ProColumns<IComment>[] = [
    {
      title: "來源",
      dataIndex: ["word", "dictionary", "name"],
      key: "dictionary",
      valueType: "select",
      valueEnum: dictionaryData?.results.reduce(
        (acc: Record<string, { text: string }>, dict: IDictionary) => {
          acc[dict.name] = { text: dict.name };
          return acc;
        },
        {}
      ),
      fieldProps: {
        onChange: (value: string) =>
          debouncedSetSearchParams({ dictionary: value }),
      },
    },
    {
      title: "漢字",
      dataIndex: ["word", "hanji"],
      key: "hanji",
      valueType: "text",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetSearchParams({ hanji: e.target.value }),
      },
    },
    {
      title: "羅馬字",
      dataIndex: ["word", "roman"],
      key: "roman",
      search: false,
    },
    {
      title: "用户",
      dataIndex: ["user", "name"],
      key: "user",
      search: false,
    },
    {
      title: "日期",
      dataIndex: "createdAt",
      valueType: "dateRange",
      key: "dateRange",
      render: (_, record) => {
        const date = new Date(record.createdAt);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      },
      sorter: true,
      fieldProps: {
        onChange: (dates: [string, string]) =>
          debouncedSetSearchParams({ dateRange: dates }),
      },
    },
    {
      title: "評論",
      dataIndex: "comment",
      ellipsis: true,
      search: false,
    },
    {
      title: "操作",
      key: "action",
      valueType: "option",
      render: (_, record: IComment) => [
        <Button
          key="view"
          icon={<FaEye />}
          onClick={() => {
            window.open(
              `${dictionaryHostUrl}/search/word/${record.word?._id || ""}`,
              "_blank"
            );
          }}
        />,
        record.isShowComment ? (
          <Button
            key="hide"
            type="text"
            style={{ color: "#593C26" }}
            onClick={() => {
              updateCommentMutation.mutate({
                id: record._id,
                isShowComment: !record.isShowComment,
              });
            }}
          >
            Hide
          </Button>
        ) : (
          <Text
            key="hide-disabled"
            style={{ color: "#D0D5DD", padding: "0px 16px" }}
          >
            Hide
          </Text>
        ),
        record.isShowComment ? (
          <Text
            key="active-disabled"
            style={{ color: "#D0D5DD", padding: "0px 16px" }}
          >
            Active
          </Text>
        ) : (
          <Button
            key="active"
            type="text"
            style={{ color: "#593C26" }}
            onClick={() => {
              updateCommentMutation.mutate({
                id: record._id,
                isShowComment: !record.isShowComment,
              });
            }}
          >
            Active
          </Button>
        ),
      ],
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>Comment Management</Title>
      </div>
      <ProTable<IComment>
        rowKey="_id"
        dataSource={data?.results}
        columns={columns}
        pagination={{
          total: data?.totalResults,
          pageSize: queryParams.limit,
          current: queryParams.page,
        }}
        loading={isLoading || updateCommentMutation.isPending}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        search={{
          labelWidth: "auto",
        }}
        onSubmit={(params: ParamsType) => {
          setQueryParams((prev) => ({ ...prev, page: 1 }));
          setSearchParams({
            dictionary: params.dictionary as string | undefined,
            hanji: params.hanji as string | undefined,
            dateRange: params.dateRange as [string, string] | undefined,
          });
        }}
        onReset={() => {
          setQueryParams({
            page: 1,
            limit: 10,
            sortBy: "",
          });
          setSearchParams({});
        }}
      />
    </div>
  );
};

export default CommentManagement;
