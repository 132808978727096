import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  createDictionary,
  editDictionary,
  getDictionary,
} from "../api/DictionaryAPI";
import {
  DictionarySchema,
  IDictionary,
} from "../type/DictionaryManagementType";

interface CreateDictionaryProps {
  id: string;
  navigate: (path: string) => void;
}

const CreateDictionary: React.FC<CreateDictionaryProps> = ({
  id,
  navigate,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDictionary>({
    resolver: zodResolver(DictionarySchema),
    mode: "onSubmit",
  });

  const { data, error: fetchError } = useQuery({
    queryKey: ["dictionary", id],
    queryFn: () => getDictionary(id),
    enabled: id !== "new",
  });

  const createMutation = useMutation<
    { status: string; data: any },
    Error,
    IDictionary
  >({
    mutationFn: createDictionary,
    onSuccess: () => {
      message.success("Dictionary created successfully!");
      navigate("/dictionary");
    },
    onError: (error: Error) => {
      message.error(`Failed to create dictionary: ${error.message}`);
    },
  });

  const editMutation = useMutation<
    { status: string; data: any },
    Error,
    IDictionary
  >({
    mutationFn: editDictionary,
    onSuccess: () => {
      message.success("Dictionary updated successfully!");
      navigate("/dictionary");
    },
    onError: (error: Error) => {
      message.error(`Failed to update dictionary: ${error.message}`);
    },
  });

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key as keyof IDictionary, data[key]);
      });
    }
  }, [data, setValue]);

  useEffect(() => {
    if (fetchError) {
      message.error(
        `Failed to fetch dictionary data: ${(fetchError as Error).message}`
      );
    }
  }, [fetchError]);

  const onFinish = async (values: IDictionary) => {
    if (id === "new") {
      createMutation.mutate(values);
    } else {
      editMutation.mutate({ ...values, id });
    }
  };

  const onError = (errors: FieldErrors<IDictionary>) => {
    console.log("Validation errors:", errors);
    message.error("Please correct the errors in the form.");
  };

  return (
    <Form onFinish={handleSubmit(onFinish, onError)} layout='vertical'>
      <Form.Item
        label='Name'
        validateStatus={errors.name ? "error" : ""}
        help={errors.name?.message}
      >
        <Controller
          name='name'
          control={control}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label='Author'
        validateStatus={errors.author ? "error" : ""}
        help={errors.author?.message}
      >
        <Controller
          name='author'
          control={control}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label='Remark'
        validateStatus={errors.remark ? "error" : ""}
        help={errors.remark?.message}
      >
        <Controller
          name='remark'
          control={control}
          render={({ field }) => <Input.TextArea {...field} />}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={createMutation.isPending || editMutation.isPending}
        >
          {id === "new" ? "Create" : "Update"}
        </Button>
        <Button
          onClick={() => navigate("/dictionary")}
          style={{ marginLeft: 8 }}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDictionary;
