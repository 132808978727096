import { ParamsType } from "@ant-design/pro-components";
import { SortOrder } from "antd/lib/table/interface";
import { z } from "zod";

export const UserSchema = z.object({
  _id: z.string(),
  role: z.string(),
  imageURL: z.string(),
  isShowLeaderBoard: z.boolean(),
  name: z.string(),
  username: z.string(),
  email: z.string(),
  password: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
});

export const DictionarySchema = z.object({
  _id: z.string(),
  category: z.number().optional(),
  name: z.string(),
  archive: z.string().optional(),
  createdAt: z.string(),
  isShowDictionary: z.boolean(),
  totalVocab: z.number(),
  updatedAt: z.string(),
  author: z.string().optional(),
  remark: z.string().optional(),
});

export const WordSchema = z.object({
  _id: z.string(),
  isApprove: z.boolean(),
  category: z.string(),
  chinese: z.string(),
  roman: z.string(),
  subCategory: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
  dictionary: DictionarySchema.optional(),
});

export const CommentSchema = z.object({
  _id: z.string(),
  isShowComment: z.boolean(),
  comment: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
  user: UserSchema.optional(),
  word: WordSchema.optional(),
});

export type IComment = z.infer<typeof CommentSchema>;

export interface IAPIResponseGetComment {
  results: IComment[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export interface IAntDProTableRequest {
  params: ParamsType & {
    pageSize?: number | undefined;
    current?: number | undefined;
    keyword?: string | undefined;
  };
  sort: Record<string, SortOrder>;
  filter: Record<string, (string | number)[] | null>;
}

// Add this to your CommentManagementType.ts file
export interface QueryParams {
  page: number;
  limit: number;
  sortBy: string;
  chinese?: string;
  dictionary?: string;
  keyword?: string;
  [key: string]: any;
}
