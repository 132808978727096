import React, { useState, useCallback, useEffect } from "react";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Typography, message, Popconfirm, Alert, Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getDictionaryList,
  deleteDictionary,
  exportDictionary,
} from "./api/DictionaryAPI";
import { IDictionary } from "./type/DictionaryManagementType";
import { FaPlus, FaEdit, FaTrash, FaBook, FaFileExport } from "react-icons/fa";
import { debounce } from "lodash";

const { Title } = Typography;

const DictionaryManagement: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState({
    current: 1,
    pageSize: 20,
  });
  const [filters, setFilters] = useState({
    name: undefined as string | undefined,
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ["dictionary", queryParams, filters],
    queryFn: () =>
      getDictionaryList({ params: { ...queryParams, ...filters } }),
  });

  const deleteMutation = useMutation({
    mutationFn: deleteDictionary,
    onSuccess: () => {
      message.success("Dictionary deleted successfully!");
      queryClient.invalidateQueries({
        queryKey: ["dictionary"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error("Error deleting dictionary:", error);
      message.error("Failed to delete dictionary.");
    },
  });

  const [exportingDictionaryId, setExportingDictionaryId] = useState<string | null>(null);

  const exportMutation = useMutation({
    mutationFn: exportDictionary,
    onSuccess: ({ data, filename }, id) => {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // Show the success message after initiating the download
      setTimeout(() => {
        message.success("Dictionary exported successfully!");
      }, 100);
      setExportingDictionaryId(null);
    },
    onError: (error) => {
      console.error("Error exporting dictionary:", error);
      message.error("Failed to export dictionary.");
      setExportingDictionaryId(null);
    },
  });

  const [pinModalVisible, setPinModalVisible] = useState(false);
  const [pin, setPin] = useState("");
  const [dictionaryToDelete, setDictionaryToDelete] = useState<string | null>(null);

  const handleDelete = (id: string) => {
    setDictionaryToDelete(id);
    setPinModalVisible(true);
  };

  const confirmDelete = () => {
    if (pin === "SpeakHokkien" && dictionaryToDelete) {
      deleteMutation.mutate(dictionaryToDelete);
      setPinModalVisible(false);
      setPin("");
      setDictionaryToDelete(null);
    } else {
      message.error("Incorrect PIN. Deletion cancelled.");
    }
  };

  const handleExport = (id: string) => {
    setExportingDictionaryId(id);
    exportMutation.mutate(id);
  };

  const debouncedSetFilters = useCallback(
    debounce((newFilters: Partial<typeof filters>) => {
      setFilters((prev) => ({ ...prev, ...newFilters }));
      setQueryParams((prev) => ({ ...prev, current: 1 })); // Reset to first page on filter change
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetFilters.cancel();
    };
  }, [debouncedSetFilters]);

  const columns: ProColumns<IDictionary>[] = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <a
          onClick={() =>
            record.id &&
            navigate(`/vocab?dictionaryId=${encodeURIComponent(record.id)}`)
          }
        >
          {text}
        </a>
      ),
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetFilters({ name: e.target.value }),
      },
    },
    {
      title: "Total Vocabulary",
      dataIndex: "totalVocab",
      search: false,
    },
    {
      title: "Author",
      dataIndex: "author",
      search: false,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      search: false,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      search: false,
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            icon={<FaEdit />}
            onClick={() => navigate(`/dictionary/${record.id}`)}
          />
          <Button
            icon={<FaTrash />}
            onClick={() => record.id && handleDelete(record.id)}
          />
          <Button
            onClick={() => record.id && handleExport(record.id)}
            loading={exportingDictionaryId === record.id}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  if (error) {
    return (
      <Alert
        message='Error loading dictionaries'
        description={(error as Error).message}
        type='error'
      />
    );
  }

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>Dictionary Management</Title>
        <Button
          type='primary'
          onClick={() => navigate("/dictionary/new")}
          icon={<FaPlus />}
        >
          Create
        </Button>
      </div>
      <ProTable<IDictionary>
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        rowKey='id'
        scroll={{ x: "max-content" }}
        pagination={{
          total: data?.totalResults,
          pageSize: queryParams.pageSize,
          current: queryParams.current,
          onChange: (page, pageSize) => {
            setQueryParams({ current: page, pageSize });
          },
        }}
        search={{
          labelWidth: "auto",
        }}
        onSubmit={(params) => {
          setFilters({ name: params.name as string | undefined });
          setQueryParams({ current: 1, pageSize: queryParams.pageSize });
        }}
        onReset={() => {
          setFilters({ name: undefined });
          setQueryParams({ current: 1, pageSize: 20 });
        }}
      />
      <Modal
        title="Enter PIN to Delete"
        open={pinModalVisible}
        onOk={confirmDelete}
        onCancel={() => {
          setPinModalVisible(false);
          setPin("");
          setDictionaryToDelete(null);
        }}
      >
        <Input
          type="password"
          placeholder="Enter PIN"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default DictionaryManagement;
