import { apiRequest } from "../../../utils/API";
import {
  IAntDProTableRequest,
  IAPIResponseGetSuggestion,
  ISuggestion,
} from "../type/SuggestionManagementType";

export const getSuggestionList = async ({
  sort = {},
  filter = {},
  params = {},
}: Partial<IAntDProTableRequest> = {}) => {
  const queryParams = new URLSearchParams();

  if (sort) {
    Object.entries(sort).forEach(([key, value]) => {
      queryParams.append("sortBy", `${key}:${value}`);
    });
  }

  if (params?.pageSize) {
    queryParams.append("limit", params.pageSize.toString());
  }

  if (params?.current) {
    queryParams.append("page", params.current.toString());
  }

  if (params?.status) {
    queryParams.append("status", params.status);
  }

  if (params?.hanji) {
    queryParams.append("hanji", params.hanji);
  }

  if (params?.roman) {
    queryParams.append("roman", params.roman);
  }

  const response = await apiRequest<IAPIResponseGetSuggestion>(
    "GET",
    `/suggestions?${queryParams.toString()}`,
    {
      params: { ...params },
    }
  );

  return response.data;
};

export const acceptSuggestions = async (id: string) => {
  const response = await apiRequest<void>("PATCH", `/suggestions/${id}/`, {
    status: "approved",
  });

  return response;
};

export const rejectSuggestions = async (id: string) => {
  const response = await apiRequest<void>("PATCH", `/suggestions/${id}/`, {
    status: "rejected",
  });

  return response;
};

export const downloadSounds = async (id: string): Promise<{ data: Blob; filename: string }> => {
  const response = await apiRequest<Blob>(
    "GET",
    `/suggestions/${id}/download`,
    {},
    {
      responseType: "blob",
    }
  );

  let filename = `suggestion_voice_${id}.mp3`; // Default filename

  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return { data: response.data, filename };
};
