import React, { useState, useCallback, useEffect } from "react";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import {
  Button,
  Typography,
  message,
  Popconfirm,
  Alert,
  Avatar,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getUserList,
  deleteUser,
  exportUsers,
  toggleUserSuspension,
} from "./api/UserManagementAPI";
import { IUser } from "./type/UserManagementType";
import { FaPlus, FaEdit, FaTrash, FaCheckCircle } from "react-icons/fa";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { debounce } from "lodash";

const { Title } = Typography;

const UserManagement: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    username: undefined as string | undefined,
    email: undefined as string | undefined,
    role: undefined as string | undefined,
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ["admin", queryParams, filters],
    queryFn: () => getUserList({ params: { ...queryParams, ...filters } }),
  });

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      message.success("User deleted successfully!");
      queryClient.invalidateQueries({
        queryKey: ["admin"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error("Error deleting user:", error);
      message.error("Failed to delete user.");
    },
  });

  const suspendMutation = useMutation({
    mutationFn: ({ id, isSuspended }: { id: string; isSuspended: boolean }) =>
      toggleUserSuspension(id, isSuspended),
    onSuccess: () => {
      message.success("User suspension status updated successfully!");
      queryClient.invalidateQueries({
        queryKey: ["admin"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error("Error updating user suspension status:", error);
      message.error("Failed to update user suspension status.");
    },
  });

  const exportMutation = useMutation({
    mutationFn: exportUsers,
    onSuccess: (data) => {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users_export.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      message.success("Users exported successfully!");
    },
    onError: (error) => {
      console.error("Error exporting users:", error);
      message.error("Failed to export users.");
    },
  });

  const handleDelete = (id: string) => {
    deleteMutation.mutate(id);
  };

  const handleSuspend = (id: string, isSuspended: boolean) => {
    suspendMutation.mutate({ id, isSuspended: !isSuspended });
  };

  const handleExport = () => {
    exportMutation.mutate();
  };

  const debouncedSetFilters = useCallback(
    debounce((newFilters: Partial<typeof filters>) => {
      setFilters((prev) => ({ ...prev, ...newFilters }));
      setQueryParams((prev) => ({ ...prev, current: 1 })); // Reset to first page on filter change
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetFilters.cancel();
    };
  }, [debouncedSetFilters]);

  const columns: ProColumns<IUser>[] = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 150,
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetFilters({ username: e.target.value }),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetFilters({ email: e.target.value }),
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 100,
      valueType: "select",
      valueEnum: {
        admin: { text: "Admin" },
        user: { text: "User" },
        moderator: { text: "Moderator" },
      },
      fieldProps: {
        onChange: (value: string) =>
          setFilters((prev) => ({ ...prev, role: value })),
      },
    },
    {
      title: "Show on Leaderboard",
      dataIndex: "isShowLeaderBoard",
      key: "isShowLeaderBoard",
      width: 150,
      search: false,
      render: (_, record) => (record.isShowLeaderBoard ? "Yes" : "No"),
    },
    {
      title: "Email Verified",
      dataIndex: "isEmailVerified",
      key: "isEmailVerified",
      width: 120,
      search: false,
      render: (_, record) => (record.isEmailVerified ? "Yes" : "No"),
    },
    {
      title: "Suspended",
      dataIndex: "isSuspended",
      key: "isSuspended",
      width: 100,
      search: false,
      render: (_, record) => (record.isSuspended ? "Yes" : "No"),
    },
    {
      title: "Last accessed",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 200,
      search: false,
      render: (_, record) => {
        if (!record.updatedAt) return "-";
        const date = dayjs(record.updatedAt);
        return date.isValid() ? date.format("DD/MM/YYYY, hh:mm a") : "-";
      },
    },
    {
      title: "Actions",
      key: "actions",
      search: false,
      fixed: "right",
      width: 200,
      render: (_, record) => (
        <>
          <Tooltip title='Edit user'>
            <Button
              icon={<FaEdit />}
              onClick={() => navigate(`/users/${record._id}`)}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Tooltip title='Delete user'>
            <Popconfirm
              title='Are you sure you want to delete this user?'
              onConfirm={() => record._id && handleDelete(record._id)}
              okText='Yes'
              cancelText='No'
            >
              <Button icon={<FaTrash />} style={{ marginRight: 8 }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title={record.isSuspended ? "Unsuspend user" : "Suspend user"}
          >
            <Popconfirm
              title={`Are you sure you want to ${record.isSuspended ? "unsuspend" : "suspend"} this user?`}
              onConfirm={() =>
                record._id &&
                handleSuspend(record._id, record.isSuspended || false)
              }
              okText='Yes'
              cancelText='No'
            >
              <Button
                icon={
                  record.isSuspended ? <FaCheckCircle /> : <CloseOutlined />
                }
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  if (error) {
    return (
      <Alert
        message='Error loading users'
        description={(error as Error).message}
        type='error'
      />
    );
  }

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={3}>User Management</Title>
        <div>
          <Button
            onClick={handleExport}
            style={{ marginRight: 8 }}
            loading={exportMutation.isPending}
          >
            Export
          </Button>
          <Button
            type='primary'
            onClick={() => navigate("/users/new")}
            icon={<FaPlus />}
          >
            Create
          </Button>
        </div>
      </div>
      <ProTable<IUser>
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        rowKey='id'
        pagination={{
          total: data?.totalResults,
          pageSize: queryParams.pageSize,
          current: queryParams.current,
          onChange: (page, pageSize) => {
            setQueryParams({ current: page, pageSize });
          },
        }}
        search={{
          labelWidth: "auto",
        }}
        onSubmit={(params) => {
          setFilters({
            email: params.name as string | undefined,
            username: params.username as string | undefined,
            role: params.role as string | undefined,
          });
          setQueryParams({ current: 1, pageSize: queryParams.pageSize });
        }}
        onReset={() => {
          setFilters({
            email: undefined,
            username: undefined,
            role: undefined,
          });
          setQueryParams({ current: 1, pageSize: 10 });
        }}
        scroll={{ x: 1600 }}
      />
    </div>
  );
};

export default UserManagement;
