import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "../pages/notFoundPage/notFoundPage";
import UserManagement from "../pages/user/UserManagement";
import AdminAddEdit from "../pages/user/view/UserAddEdit";
import DictionaryManagement from "../pages/dictionary/DictionaryManagement";
import DictionaryAddEdit from "../pages/dictionary/view/DictionaryAddEdit";
import CommentManagement from "../pages/comment/CommentManagement";
import CommentAddEdit from "../pages/comment/view/CommentAddEdit";
import VocabManagement from "../pages/vocab/VocabManagement";
import VocabAddEdit from "../pages/vocab/view/VocabAddEdit";
import SuggestionManagement from "../pages/suggestion/SuggestionManagement";
import AnnouncementManagement from "../pages/announcement/AnnouncementManagement";
import AnnouncementAddEdit from "../pages/announcement/view/AnnouncementAddEdit";

export default function RouteList() {
  const handleAdminRoutes = () => (
    <>
      <Route path='/users' element={<UserManagement />} />
      <Route path='/users/add' element={<AdminAddEdit />} />
      <Route path='/users/:id' element={<AdminAddEdit />} />
      <Route path='/dictionary' element={<DictionaryManagement />} />
      <Route path='/dictionary/:id' element={<DictionaryAddEdit />} />
      <Route path='/dictionary/new' element={<DictionaryAddEdit />} />
      <Route path='/comment' element={<CommentManagement />} />
      <Route path='/comment/:id' element={<CommentAddEdit />} />
      <Route path='/vocab' element={<VocabManagement />} />
      <Route path='/vocab/:id' element={<VocabAddEdit />} />
      <Route path='/vocab/new' element={<VocabAddEdit />} />
      <Route path='/suggestion' element={<SuggestionManagement />} />
      <Route path='/announcement' element={<AnnouncementManagement />} />
      <Route path='/announcement/:id' element={<AnnouncementAddEdit />} />
      <Route path='/announcement/new' element={<AnnouncementAddEdit />} />
      <Route path='*' element={NotFoundPage()} />
    </>
  );

  // TODO modify when API implementation is done
  const handleRolesRoutes = (roles: string) => {
    switch (roles) {
      case "moderator":
        return NotFoundPage();
      case "admin":
        return handleAdminRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Routes>{handleRolesRoutes("admin")}</Routes>;
}
