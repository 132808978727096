import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  ActionType,
  ProColumns,
  ProTable,
  ParamsType,
} from "@ant-design/pro-components";
import { Button, Typography, message, Tag, Popover } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getSuggestionList,
  acceptSuggestions,
  rejectSuggestions,
  downloadSounds,
} from "./api/SuggestionAPI";
import {
  ISuggestion,
  IAntDProTableRequest,
  IAPIResponseGetSuggestion,
} from "./type/SuggestionManagementType";
import { DownloadOutlined, SoundOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { debounce } from "lodash";
import { ReactComponent as StopIcon } from "../../assets/svg/stop-icon.svg";

const { Title } = Typography;

const AudioPlayer: React.FC<{
  url: string;
  onDownload: () => void;
  isDownloading: boolean;
}> = ({ url, onDownload, isDownloading }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleEnded = () => setIsPlaying(false);
      audioElement.addEventListener("ended", handleEnded);
      return () => {
        audioElement.removeEventListener("ended", handleEnded);
      };
    }
  }, []);

  return (
    <Space size="small">
      <Button
        icon={
          isPlaying ? (
            <StopIcon style={{ width: "12px", height: "12px" }} />
          ) : (
            <SoundOutlined />
          )
        }
        onClick={toggleAudio}
      />
      <Button
        icon={<DownloadOutlined />}
        onClick={onDownload}
        loading={isDownloading}
      />
      <audio ref={audioRef} src={url} />
    </Space>
  );
};

const SuggestionManagement: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useState<{
    status?: string;
    hanji?: string;
    roman?: string;
  }>({});

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["suggestions", tableParams, searchParams],
    queryFn: () =>
      getSuggestionList({
        params: {
          current: tableParams.current,
          pageSize: tableParams.pageSize,
          ...searchParams,
        },
        sort: {},
        filter: {},
      }),
  });

  const rejectMutation = useMutation({
    mutationFn: rejectSuggestions,
    onSuccess: () => {
      refetch();
      message.success("Suggestion rejected successfully!");
    },
    onError: (error) => {
      console.error("Error rejecting suggestion:", error);
      message.error("Failed to reject suggestion.");
    },
  });

  const acceptMutation = useMutation({
    mutationFn: acceptSuggestions,
    onSuccess: () => {
      refetch();
      message.success("Suggestion accepted successfully!");
    },
    onError: (error) => {
      console.error("Error accepting suggestion:", error);
      message.error("Failed to accept suggestion.");
    },
  });

  const [downloadingSuggestionId, setDownloadingSuggestionId] = useState<
    string | null
  >(null);

  const downloadMutation = useMutation({
    mutationFn: downloadSounds,
    onSuccess: ({ data, filename }, id) => {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      message.success('Download started. Check your downloads folder.');
      setDownloadingSuggestionId(null);
      
      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      console.error("Error downloading voice:", error);
      message.error("Failed to initiate download.");
      setDownloadingSuggestionId(null);
    },
  });

  const handleDownloadVoice = (id: string) => {
    setDownloadingSuggestionId(id);
    downloadMutation.mutate(id);
  };

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return <Tag color="orange">Pending</Tag>;
      case "approved":
        return <Tag color="green">Approved</Tag>;
      case "rejected":
        return <Tag color="red">Rejected</Tag>;
      default:
        return <Tag color="geekblue">{status}</Tag>;
    }
  };

  const debouncedUpdateSearchParams = useCallback(
    debounce((newParams: typeof searchParams) => {
      setSearchParams(newParams);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSearchParams.cancel();
    };
  }, [debouncedUpdateSearchParams]);

  const columns: ProColumns<ISuggestion>[] = [
    {
      title: "漢字",
      dataIndex: "hanji",
      width: "auto",
      valueType: "text",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedUpdateSearchParams({
            ...searchParams,
            hanji: e.target.value,
          }),
      },
    },
    {
      title: "羅馬字",
      dataIndex: "roman",
      width: "auto",
      valueType: "text",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedUpdateSearchParams({
            ...searchParams,
            roman: e.target.value,
          }),
      },
    },
    {
      title: "Word's Origin",
      dataIndex: "originWord",
      width: "auto",
      valueType: "text",
      search: false,
      render: (_, record) => record.originWord || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => getStatusTag(record.status),
      width: "auto",
      valueType: "select",
      valueEnum: {
        pending: { text: "Pending", status: "Warning" },
        approved: { text: "Approved", status: "Success" },
        rejected: { text: "Rejected", status: "Error" },
      },
      fieldProps: {
        onChange: (value: string) =>
          debouncedUpdateSearchParams({ ...searchParams, status: value }),
      },
    },
    {
      title: "Audio",
      dataIndex: "recordURL",
      width: "auto",
      search: false,
      render: (_, record) =>
        record.recordURL ? (
          <AudioPlayer
            url={record.recordURL}
            onDownload={() => record._id && handleDownloadVoice(record._id)}
            isDownloading={downloadingSuggestionId === record._id}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "漢字例句",
      dataIndex: "exampleChinese",
      ellipsis: true,
      search: false,
      render: (_, record) => renderExamples(record.exampleChinese),
    },
    {
      title: "User",
      renderText(text, record, index, action) {
        return record?.user?.username || "-";
      },
      width: "auto",
      search: false,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      renderText(text, record, index, action) {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        const formattedTime = date
          .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .toLowerCase();
        return `${formattedDate}, ${formattedTime}`;
      },
      width: "auto",
      search: false,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      search: false,
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="primary"
            onClick={() => acceptMutation.mutate(record._id)}
            disabled={record.status === "approved"}
          >
            Approve
          </Button>
          <Button
            onClick={() => rejectMutation.mutate(record._id)}
            disabled={record.status === "rejected"}
          >
            Reject
          </Button>
        </div>
      ),
      width: "auto",
      fixed: "right",
    },
  ];

  const renderExamples = (examples: any[]) => {
    if (!examples || examples.length === 0) {
      return "-";
    }

    const visibleExamples = examples.slice(0, 2);
    const remainingExamples = examples.slice(2);

    const exampleList = (
      <ol style={{ margin: 0, paddingLeft: "20px" }}>
        {visibleExamples.map((example, index) => (
          <li key={index}>
            {typeof example === "object" ? example.example : example}
          </li>
        ))}
      </ol>
    );

    if (remainingExamples.length === 0) {
      return exampleList;
    }

    const allExamples = (
      <ol style={{ margin: 0, paddingLeft: "20px" }}>
        {examples.map((example, index) => (
          <li key={index}>
            {typeof example === "object" ? example.example : example}
          </li>
        ))}
      </ol>
    );

    return (
      <>
        {exampleList}
        <Popover content={allExamples} title="All Examples">
          <a>See all ({examples.length})</a>
        </Popover>
      </>
    );
  };

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>User Suggestions</Title>
      </div>
      <ProTable<ISuggestion>
        actionRef={actionRef}
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        search={{
          labelWidth: "auto",
        }}
        options={false}
        onSubmit={(params: ParamsType) => {
          setTableParams({
            current: 1,
            pageSize: tableParams.pageSize,
          });
          setSearchParams({
            status: params.status as string | undefined,
            hanji: params.hanji as string | undefined,
            roman: params.roman as string | undefined,
          });
        }}
        onReset={() => {
          setTableParams({
            current: 1,
            pageSize: 10,
          });
          setSearchParams({});
          actionRef.current?.reload();
        }}
        pagination={{
          total: data?.totalResults,
          ...tableParams,
          onChange: (page, pageSize) => {
            setTableParams({
              current: page,
              pageSize,
            });
          },
        }}
        rowKey="_id"
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default SuggestionManagement;
