import React, { useState, useEffect } from "react";
import { Layout, Space, Typography } from "antd";
import { useLocation } from "react-router-dom";
import SidebarMenu from "./sidebarMenu/sidebarMenu";
import RouteList from "../../routes/RouteList";
import DynamicBreadcrumb from "../breadcrumb/Breadcrumb";
import { getAnnouncement } from "../../pages/announcement/api/AnnouncementAPI";
import { getVocab } from "../../pages/vocab/api/VocabAPI";
import { getDictionary } from "../../pages/dictionary/api/DictionaryAPI";
import { getUser } from "../../utils/AuthService";
import { getUserDetails } from "../../pages/user/api/UserManagementAPI";

const { Header, Sider, Content } = Layout;
const { Text, Link } = Typography;

type TDashboardLayout = {
  children: React.ReactNode;
};

const DashboardLayout = ({ children }: TDashboardLayout) => {
  const [userName, setUserName] = useState("");
  const [customLabels, setCustomLabels] = useState<{ [key: string]: string }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Effect to set the user name
  useEffect(() => {
    const user = getUser();
    if (user && typeof user === "object" && user.username) {
      setUserName(user.username);
    } else {
      console.error("User data is not in the expected format");
    }
  }, []);

  // Effect to fetch custom labels for breadcrumb
  useEffect(() => {
    const fetchCustomLabel = async () => {
      setIsLoading(true);
      const paths = location.pathname.split("/").filter(Boolean);
      const lastPath = paths[paths.length - 1];
      const secondLastPath = paths[paths.length - 2];

      if (lastPath === "new") {
        setCustomLabels((prev) => ({ ...prev, [location.pathname]: "New" }));
        setIsLoading(false);
        return;
      }

      try {
        let newLabel = "";

        // Fetch appropriate data based on the current route
        switch (secondLastPath) {
          case "announcement":
            newLabel = await fetchAnnouncementTitle(lastPath);
            break;
          case "users":
            newLabel = await fetchUserName(lastPath);
            break;
          case "vocab":
            newLabel = await fetchVocabHanji(lastPath);
            break;
          case "dictionary":
            newLabel = await fetchDictionaryName(lastPath);
            break;
          default:
            break;
        }

        if (newLabel) {
          setCustomLabels((prev) => ({
            ...prev,
            [location.pathname]: newLabel,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomLabel();
  }, [location]);

  // Helper functions to fetch specific data
  const fetchAnnouncementTitle = async (id: string): Promise<string> => {
    const announcement = await getAnnouncement(id);
    return announcement.title;
  };

  const fetchUserName = async (id: string): Promise<string> => {
    const user = await getUserDetails(id);
    return user.username;
  };

  const fetchVocabHanji = async (id: string): Promise<string> => {
    const vocab = await getVocab(id);
    return vocab.hanji;
  };

  const fetchDictionaryName = async (id: string): Promise<string> => {
    const dictionary = await getDictionary(id);
    return dictionary.name;
  };

  return (
    <Layout style={{ height: "100vh" }}>
      {/* Sidebar */}
      <Sider
        theme='light'
        breakpoint='lg'
        collapsedWidth='0'
        style={{ height: "100%", backgroundColor: "#1C212D" }}
      >
        {/* Logo */}
        <Link
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          href='/'
        >
          <div style={{ height: 64 }}></div>
          <img
            src='https://storage-hokkien.s3.ap-southeast-1.amazonaws.com/default/logo-admin.svg'
            alt='logo'
          />
        </Link>
        {/* Sidebar Menu */}
        <SidebarMenu />
      </Sider>
      <Layout>
        {/* Header */}
        <Header style={{ width: "100%", borderBottom: "2px solid #DBDBDB" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            {/* Dynamic Breadcrumb */}
            <DynamicBreadcrumb
              customLabels={customLabels}
              isLoading={isLoading}
            />
            {/* User Name */}
            <Text>{userName}</Text>
          </div>
        </Header>
        {/* Main Content */}
        <Content
          style={{
            height: "calc(100vh - 64px)",
            overflow: "auto",
            padding: "24px",
          }}
        >
          <Space direction='vertical' size='large' style={{ width: "100%" }}>
            {RouteList()}
          </Space>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
