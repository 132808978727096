import axios, {
  AxiosResponse,
  AxiosError,
  Method,
  AxiosRequestConfig,
} from "axios";
import {
  getToken,
  getRememberMe,
  setToken,
  removeUserSession,
} from "../utils/AuthService";

const apiHost = `${process.env.REACT_APP_API_HOST}`;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const tokens = getToken();

    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens.access.token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const refreshToken = async () => {
  const tokens = getToken();
  const rememberMe = getRememberMe();

  try {
    const resp = await axiosInstance.post("v1/auth/refresh-tokens", {
      refreshToken: tokens?.refresh.token,
      rememberMe,
    });
    return resp.data;
  } catch (e) {
    console.log("Error", e);
    handleLogout();
  }
};

const handleLogout = () => {
  removeUserSession();
  window.location.href = "/";
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      handleLogout();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

async function apiRequest<T>(
  method: Method,
  url: string,
  data: any = {},
  config: AxiosRequestConfig = {}
) {
  const token = getToken();
  try {
    const response: AxiosResponse = await axiosInstance({
      method,
      headers: {
        Authorization: token ? `Bearer ${token.access.token}` : "",
        ...config.headers,
      },
      url: apiHost + url,
      data,
      ...config,
    });
    return {
      status: "success",
      data: response.data,
      headers: response.headers,
    };
  } catch (error: any) {
    if (error.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error.response?.data || error);
  }
}

export { apiRequest };
