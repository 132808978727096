import { SortOrder } from "antd/es/table/interface"
import { z } from "zod"

const ExampleSchema = z.object({
  id: z.string().optional(),
  exampleIndexId: z.string().optional(),
  exampleHanji: z.string().optional(),
  exampleRoman: z.string().optional(),
  exampleChinese: z.string().optional(),
  exampleEnglish: z.string().optional(),
  exampleMalay: z.string().optional(),
  exampleTagalog: z.string().optional(),
  exampleIndonesia: z.string().optional(),
})

const WordSynonymSchema = z.object({
  id: z.string().optional(),
  wordSynonymIndexId: z.string().optional(),
  relativeWordIndexId: z.string().optional(),
  relativeHanji: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const WordAntonymSchema = z.object({
  id: z.string().optional(),
  wordAntonymIndexId: z.string().optional(),
  relativeWordIndexId: z.string().optional(),
  relativeHanji: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const MeaningSynonymSchema = z.object({
  id: z.string().optional(),
  meaningSynonymIndexId: z.string().optional(),
  relativeMeaningIndexId: z.string().optional(),
  relativeMeaningChinese: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const MeaningAntonymSchema = z.object({
  id: z.string().optional(),
  meaningAntonymIndexId: z.string().optional(),
  relativeMeaningIndexId: z.string().optional(),
  relativeHanji: z.string().optional(),
  relativeMeaningChinese: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const MeaningWordSynonymSchema = z.object({
  id: z.string().optional(),
  meaningWordSynonymIndexId: z.string().optional(),
  relativeMeaningIndexId: z.string().optional(),
  relativeWordIndexId: z.string().optional(),
  relativeHanji: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const MeaningWordAntonymSchema = z.object({
  id: z.string().optional(),
  meaningWordAntonymIndexId: z.string().optional(),
  relativeMeaningIndexId: z.string().optional(),
  relativeWordIndexId: z.string().optional(),
  relativeHanji: z.string().optional(),
  relativeRoman: z.string().optional(),
})

const MeaningSchema = z.object({
  id: z.string().optional(),
  meaningIndexId: z.string().optional(),
  partOfSpeech: z.string().optional(),
  meaningChinese: z.string().optional(),
  meaningEnglish: z.string().optional(),
  meaningHokkien: z.string().optional(),
  meaningTagalog: z.string().optional(),
  meaningIndonesia: z.string().optional(),
  meaningMalay: z.string().optional(),
  meaningSynonym: z.array(MeaningSynonymSchema).optional(),
  meaningAntonym: z.array(MeaningAntonymSchema).optional(),
  meaningWordSynonym: z.array(MeaningWordSynonymSchema).optional(),
  meaningWordAntonym: z.array(MeaningWordAntonymSchema).optional(),
  example: z.array(ExampleSchema).optional(),
})

const AlternativeCharacterSchema = z.object({
  id: z.string().optional(),
  alternativeCharacterIndexId: z.string().optional(),
  alternativeCharacterHanji: z.string().optional(),
})

const AlternativePronounceSchema = z.object({
  id: z.string().optional(),
  alternativePronounceIndexId: z.string().optional(),
  alternativePronounceRoman: z.string().optional(),
})

const MixWordSchema = z.object({
  id: z.string().optional(),
  mixWordIndexId: z.string().optional(),
  mixWordRoman: z.string().optional(),
})

const CorruptedPronounceSchema = z.object({
  id: z.string().optional(),
  corruptedPronounceIndexId: z.string().optional(),
  corruptedPronounceRoman: z.string().optional(),
})

const AccentSchema = z.object({
  id: z.string().optional(),
  accentIndexId: z.string().optional(),
  accentLugang: z.string().optional(),
  accentSanxia: z.string().optional(),
  accentTaipei: z.string().optional(),
  accentYilan: z.string().optional(),
  accentTainan: z.string().optional(),
  accentGaoxiong: z.string().optional(),
  accentJinmen: z.string().optional(),
  accentMagong: z.string().optional(),
  accentXinchu: z.string().optional(),
  accentTaichung: z.string().optional(),
})

// Define the main Vocabulary schema
export const VocabSchema = z.object({
  id: z.string().optional(),
  wordIndexId: z.string().optional(),
  hanji: z.string().min(1),
  roman: z.string().min(1),
  category: z.string().optional(),
  subCategory: z.string().optional(),
  etymology: z.string().optional(),
  dictionaryId: z.string(),
  wordSynonym: z.array(WordSynonymSchema).optional(),
  wordAntonym: z.array(WordAntonymSchema).optional(),
  meaning: z.array(MeaningSchema).optional(),
  alternativeCharacter: z.array(AlternativeCharacterSchema).optional(),
  alternativePronounce: z.array(AlternativePronounceSchema).optional(),
  mixWord: z.array(MixWordSchema).optional(),
  corruptedPronounce: z.array(CorruptedPronounceSchema).optional(),
  accent: AccentSchema.optional(),
  sound: z.any().optional(),
})

export type IVocab = z.infer<typeof VocabSchema>

// Define the API response schema
const APIResponseSchema = z.object({
  results: z.array(VocabSchema),
  page: z.number(),
  limit: z.number(),
  totalPages: z.number(),
  totalResults: z.number(),
})

// Define the type for API response
export type IAPIResponse = z.infer<typeof APIResponseSchema>

export interface IAntDProTableRequest {
  params: {
    pageSize?: number
    current?: number
  }
  filter?: {
    dictionaryId?: string
    category?: string
    hanji?: string
    roman?: string
    subCategory?: string
  }
  sort?: Record<string, SortOrder>
}
export type IVocabCreateRequest = IVocab

export interface IVocabUpdateRequest extends Partial<IVocab> {
  id: string
}

export interface SearchWordsResponse {
  results: WordOption[]
  page: number
  limit: number
  totalPages: number
  totalResults: number
}

export interface WordOption {
  id: string
  wordIndexId: string
  hanji: string
  roman: string
  chinese: string
  english: string
}

export interface SearchMeaningsResponse {
  results: MeaningOption[]
  page: number
  limit: number
  totalPages: number
  totalResults: number
}

export interface MeaningOption {
  _id: string
  meaningIndexId: string
  partOfSpeech: string
  wordId: string
  meaningChinese?: string
  meaningEnglish?: string
}
