import React from "react";
import { ConfigProvider } from "antd";
import en_US from "antd/locale/en_US";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "@fontsource/inter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const primaryButtonColor = "#FF0000";
root.render(
  <ConfigProvider
    locale={en_US}
    theme={{
      token: {
        colorPrimary: "#7E5435",
        fontFamily: "GenSekiGothic",
      },
      components: {
        Layout: {
          colorBgHeader: "white",
          colorText: "#6C6C6C",
        },
        Menu: {
          itemBg: "rgb(28, 33, 45)",
          itemSelectedBg: "rgb(126, 84, 53)",
          itemSelectedColor: "rgb(255, 255, 255)",
          itemColor: "rgba(255, 255, 255, 0.88)",
          popupBg: "rgb(0, 0, 0)",
          itemHoverColor: "rgba(255, 255, 255, 0.88)",
          itemActiveBg: "rgb(0, 0, 0)",
        },
        Input: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
        Select: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
);

reportWebVitals();
