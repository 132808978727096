import { apiRequest } from "../../../utils/API"
import {
  IAntDProTableRequest,
  IAPIResponseGetUsers,
  IUser,
} from "../type/UserManagementType"

export const getUserList = async ({
  params = {},
  sorter = {},
  filter = {},
}: {
  params?: Record<string, any>
  sorter?: Record<string, string>
  filter?: Record<string, any>
}) => {
  const queryParams = new URLSearchParams()

  // Handle pagination
  if (params.current) queryParams.append("page", params.current.toString())
  if (params.pageSize) queryParams.append("limit", params.pageSize.toString())

  // Handle sorting
  if (sorter) {
    Object.entries(sorter).forEach(([key, value]) => {
      queryParams.append("sortBy", `${key}:${value}`)
    })
  }

  // Handle filtering
  if (params.username) queryParams.append("name", params.username.toString())
  if (params.email) queryParams.append("email", params.email.toString())
  if (params.role) queryParams.append("role", params.role.toString())

  // Handle keyword search
  // if (params.keyword) queryParams.append("keyword", params.keyword);

  const response = await apiRequest<IAPIResponseGetUsers>(
    "GET",
    `/users?${queryParams.toString()}`
  )

  return response.data
}

export const createUser = async (data: IUser) => {
  return await apiRequest<IUser>("POST", "/users", {
    username: data.username,
    email: data.email,
    role: data.role,
    password: data.password,
    /// When creating a new user as an admin, isEmailVerified should be set to true.
    isEmailVerified: true,
  })
}

export const editUser = async (data: IUser) => {
  return await apiRequest<IUser>("PATCH", `/users/${data._id}`, {
    username: data.username,
    email: data.email,
    role: data.role,
    password: data.password,
    // When editing a user, isEmailVerified should not be changed.
  })
}

export const getUserDetails = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetUsers>("GET", `/users/${id}`)
  return response.data
}

export const deleteUser = async (id: string) => {
  return await apiRequest<void>("PATCH", `/users/${id}/archive`)
}

export const toggleUserSuspension = async (
  id: string,
  isSuspended: boolean
) => {
  return await apiRequest<IUser>("PATCH", `/users/${id}`, {
    isSuspended: isSuspended,
  })
}

export const exportUsers = async (): Promise<Blob> => {
  const response = await apiRequest<Blob>(
    "GET",
    "/users/export",
    {},
    {
      responseType: "blob",
    }
  )
  return response.data
}
