import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "../components/layout/DashboardLayout";
import { isAuthenticated, isAdmin } from "../utils/AuthService";

export const PrivateRoute = () => {
  return isAuthenticated() ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to='/login' />
  );
};

export const AdminRoute = () => {
  return isAuthenticated() ? (
    isAdmin() ? (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ) : (
      <Navigate to='/vocab' />
    )
  ) : (
    <Navigate to='/login' />
  );
};

export const PublicRoute = () => {
  return !isAuthenticated() ? <Outlet /> : <Navigate to='/vocab' />;
};