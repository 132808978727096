import { apiRequest } from "../../../utils/API";
import {
  IAntDProTableRequest,
  IAPIResponseGetComment,
  IComment,
  QueryParams,
} from "../type/CommentManagementType";

export const getCommentList = async (params: QueryParams) => {
  const queryParams = new URLSearchParams();

  if (params.page) queryParams.append("page", params.page.toString());
  if (params.limit) queryParams.append("limit", params.limit.toString());
  if (params.sortBy) queryParams.append("sortBy", params.sortBy);
  if (params.dictionary) queryParams.append("dictionary", params.dictionary);
  if (params.hanji) queryParams.append("hanji", params.hanji);

  // Handle dateRange as an array
  if (params.dateRange && Array.isArray(params.dateRange)) {
    params.dateRange.forEach((date) => {
      if (date) queryParams.append("dateRange", date);
    });
  }

  const response = await apiRequest<IAPIResponseGetComment>(
    "GET",
    `/comments?${queryParams.toString()}`
  );

  return response.data;
};

// TODO: Remove this function
export const createComment = async (data: IComment) => {
  return;
};

export const getComment = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetComment>(
    "GET",
    `/comments/${id}`
  );
  return response.data;
};

export const editComment = async (data: IComment) => {
  return await apiRequest<IComment>("PATCH", `/comments/${data._id}`, {
    userId: data?.user?._id,
    wordId: data?.word?._id,
    comment: data.comment,
    isShowComment: data.isShowComment,
  });
};

export const updateIsShowComment = async (
  id: string,
  isShowComment: boolean
) => {
  const response = await apiRequest<IComment>("PATCH", `/comments/${id}`, {
    isShowComment,
  });
  return response.data;
};

export const deleteComment = async (id: string) => {
  const response = await apiRequest<void>("PATCH", `/comments/${id}/archive`);

  console.log(response);

  return response;
};
