import React, { useEffect, useState } from "react";
import { Form, Input, Switch, Button, message } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createAnnouncement,
  getAnnouncement,
  updateAnnouncement,
} from "../api/AnnouncementAPI";

const AnnouncementAddEdit: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    setIsCreating(location.pathname === "/announcement/new");
  }, [location]);

  const { data: announcement, isLoading } = useQuery({
    queryKey: ["announcement", id],
    queryFn: () => getAnnouncement(id!),
    enabled: !!id && !isCreating,
  });

  useEffect(() => {
    if (announcement && !isCreating) {
      form.setFieldsValue(announcement);
    }
  }, [announcement, form, isCreating]);

  const createMutation = useMutation({
    mutationFn: createAnnouncement,
    onSuccess: () => {
      message.success("Announcement created successfully");
      navigate("/announcement");
    },
    onError: (error) => {
      message.error(`Failed to create announcement: ${error}`);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data: {
      title: string;
      description: string;
      isShowing: boolean;
    }) => updateAnnouncement(id!, data),
    onSuccess: () => {
      message.success("Announcement updated successfully");
      navigate("/announcement");
    },
    onError: (error) => {
      message.error(`Failed to update announcement: ${error}`);
    },
  });

  const onFinish = (values: any) => {
    if (isCreating) {
      createMutation.mutate({
        title: values.title,
        description: values.description,
      });
    } else {
      updateMutation.mutate({
        title: values.title,
        description: values.description,
        isShowing: values.isShowing,
      });
    }
  };

  if (isLoading && !isCreating) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      <h2>{isCreating ? "Create Announcement" : "Edit Announcement"}</h2>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{ isShowing: true }}
      >
        <Form.Item
          name='title'
          label='Title'
          rules={[
            {
              required: true,
              message: "Please input the announcement title",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='description'
          label='Description'
          rules={[
            {
              required: true,
              message: "Please input the announcement description",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        {!isCreating && (
          <Form.Item name='isShowing' label='Status' valuePropName='checked'>
            <Switch checkedChildren='Active' unCheckedChildren='Inactive' />
          </Form.Item>
        )}
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {isCreating ? "Create" : "Update"}
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => navigate("/announcement")}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AnnouncementAddEdit;
