import React, { useState, useCallback, useEffect } from "react";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Typography, message, Popconfirm, Alert, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { getVocabList, archiveVocab } from "./api/VocabAPI";
import {
  IVocab,
  IAntDProTableRequest,
  IAPIResponse,
} from "./type/VocabManagementType";
import { debounce } from "lodash";
import { getDictionaryList } from "../dictionary/api/DictionaryAPI"; // Add this import
import { IDictionary } from "../dictionary/type/DictionaryManagementType";

const { Title } = Typography;

const VocabManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const searchParams = new URLSearchParams(location.search);
  const dictionaryFilter = searchParams.get("dictionaryId");

  const [queryParams, setQueryParams] = useState<IAntDProTableRequest>({
    params: {
      current: 1,
      pageSize: 10,
    },
    filter: {
      dictionaryId: dictionaryFilter || undefined,
    },
  });

  const [selectedDictionaryId, setSelectedDictionaryId] = useState<
    string | undefined
  >(dictionaryFilter || undefined);

  // Fetch dictionary list
  const { data: dictionaryData } = useQuery({
    queryKey: ["dictionaryList"],
    queryFn: () => getDictionaryList({ params: { pageSize: 1000 } }),
  });

  // Fetch selected dictionary details
  const { data: selectedDictionary } = useQuery({
    queryKey: ["dictionary", selectedDictionaryId],
    queryFn: () => getDictionaryList({ params: { id: selectedDictionaryId } }),
    enabled: !!selectedDictionaryId,
  });

  useEffect(() => {
    if (selectedDictionary?.results?.length === 1) {
      const dictionary = selectedDictionary.results[0];
      setQueryParams((prev) => ({
        ...prev,
        filter: { ...prev.filter, dictionaryId: dictionary.id },
      }));
    }
  }, [selectedDictionary]);

  const { data, isLoading, error } = useQuery<IAPIResponse, Error>({
    queryKey: ["vocabList", queryParams],
    queryFn: () => getVocabList(queryParams),
    staleTime: 5 * 60 * 1000, // Data stays fresh for 5 minutes
    gcTime: 10 * 60 * 1000, // Cache is kept for 10 minutes (garbage collection time)
  });

  const archiveMutation = useMutation({
    mutationFn: archiveVocab,
    onSuccess: () => {
      message.success("Vocabulary archived successfully!");
      queryClient.invalidateQueries({ queryKey: ["vocabList"] });
    },
    onError: (error) => {
      console.error("Error archiving vocabulary:", error);
      message.error("Failed to archive vocabulary.");
    },
  });

  const handleArchive = (id: string) => {
    archiveMutation.mutate(id);
  };

  const debouncedSetQueryParams = useCallback(
    debounce((newParams: Partial<IAntDProTableRequest>) => {
      setQueryParams((prev) => ({
        ...prev,
        ...newParams,
        params: { ...prev.params, current: 1 }, // Reset to first page on filter change
      }));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetQueryParams.cancel();
    };
  }, [debouncedSetQueryParams]);

  const columns: ProColumns<IVocab>[] = [
    {
      title: "Index ID",
      dataIndex: "wordIndexId",
      key: "wordIndexId",
      search: false,
    },
    {
      title: "來源",
      dataIndex: "dictionaryId",
      key: "dictionaryId",
      valueType: "select",
      valueEnum: dictionaryData?.results.reduce(
        (acc: Record<string, { text: string }>, dict: IDictionary) => {
          const id = dict.id ?? "unknown";
          acc[id] = { text: dict.name ?? "Unnamed" };
          return acc;
        },
        {}
      ),
      fieldProps: {
        value: selectedDictionaryId,
        onChange: (value: string) => {
          setSelectedDictionaryId(value);
          debouncedSetQueryParams({
            filter: { ...queryParams.filter, dictionaryId: value },
          });
        },
      },
    },
    {
      title: "漢字",
      dataIndex: "hanji",
      key: "hanji",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetQueryParams({
            filter: { ...queryParams.filter, hanji: e.target.value },
          }),
      },
    },
    {
      title: "羅馬字",
      dataIndex: "roman",
      key: "roman",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetQueryParams({
            filter: { ...queryParams.filter, roman: e.target.value },
          }),
      },
    },
    {
      title: "分類",
      dataIndex: "category",
      key: "category",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetQueryParams({
            filter: { ...queryParams.filter, category: e.target.value },
          }),
      },
    },
    {
      title: "子分類",
      dataIndex: "subCategory",
      key: "subCategory",
      fieldProps: {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          debouncedSetQueryParams({
            filter: { ...queryParams.filter, subCategory: e.target.value },
          }),
      },
    },
    {
      title: "詞性",
      dataIndex: ["meaning", 0, "partOfSpeech"],
      key: "partOfSpeech",
      search: false,
    },
    {
      title: "對應華文",
      dataIndex: ["meaning", 0, "meaningChinese"],
      key: "chinese",
      search: false,
    },
    {
      title: "對應英文",
      dataIndex: ["meaning", 0, "meaningEnglish"],
      key: "english",
      search: false,
    },
    {
      key: "action",
      search: false,
      render: (_, record: IVocab) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            icon={<FaEdit />}
            onClick={() => navigate(`/vocab/${record.id}`)}
            title="Edit"
          />
          <Popconfirm
            title="Are you sure you want to archive this vocabulary?"
            onConfirm={() => record.id && handleArchive(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<FaTrash />} title="Archive" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  if (error) {
    return (
      <Alert
        message="Error loading vocabularies"
        description={error.message}
        type="error"
      />
    );
  }

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3}>Vocabulary Management</Title>
        <Button
          type="primary"
          onClick={() => navigate("/vocab/new")}
          icon={<FaPlus />}
        >
          Create
        </Button>
      </div>
      <ProTable<IVocab>
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        rowKey="_id"
        pagination={{
          total: data?.totalResults || 0,
          pageSize: 10,
          current: queryParams.params.current || 1,
          onChange: (page) => {
            setQueryParams((prev) => ({
              ...prev,
              params: {
                ...prev.params,
                current: page || 1,
                pageSize: 10,
              },
            }));
          },
          showSizeChanger: false,
        }}
        search={{
          labelWidth: "auto",
        }}
        onSubmit={(params) => {
          setQueryParams((prev) => ({
            ...prev,
            params: {
              current: 1,
              pageSize: 10,
            },
            filter: {
              dictionary: params.dictionary,
              category: params.category,
              hanji: params.hanji,
              roman: params.roman,
              subCategory: params.subCategory,
            },
          }));
        }}
        onReset={() => {
          setQueryParams({
            params: { current: 1, pageSize: 10 }, // Reset to page 1 with 10 items
            filter: { dictionaryId: undefined },
          });
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default VocabManagement;
