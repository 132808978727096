import React, { useState, useEffect } from "react";
import { Breadcrumb, Typography, Skeleton } from "antd";
import { Link, useLocation } from "react-router-dom";

const { Text } = Typography;

interface DynamicBreadcrumbProps {
  customLabels?: { [key: string]: string };
  isLoading?: boolean;
}

const DynamicBreadcrumb: React.FC<DynamicBreadcrumbProps> = ({
  customLabels = {},
  isLoading = false,
}) => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<
    { title: JSX.Element | string }[]
  >([]);

  useEffect(() => {
    // Function to generate breadcrumbs based on the current location
    const generateBreadcrumbs = () => {
      const crumbs: { title: JSX.Element | string }[] = [];
      const routes = location.pathname.split("/").filter(Boolean);

      routes.forEach((route, index) => {
        const path = `/${routes.slice(0, index + 1).join("/")}`;
        // Use custom label if available, otherwise use uppercase route name
        const label = customLabels[path] || route.toUpperCase();

        if (index < routes.length - 1) {
          // For all but the last item, create a clickable link
          crumbs.push({
            title: <Link to={path}>{label}</Link>,
          });
        } else {
          // For the last item, show as bold text or empty div if loading
          crumbs.push({
            title: isLoading ? (
              <div />
            ) : (
              <Text style={{ color: "#0C377C", fontWeight: "bold" }}>
                {label}
              </Text>
            ),
          });
        }
      });

      setBreadcrumbs(crumbs);
    };

    generateBreadcrumbs();
  }, [location, customLabels, isLoading]);

  return <Breadcrumb items={breadcrumbs} separator='>' />;
};

export default DynamicBreadcrumb;
