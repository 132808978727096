import { apiRequest } from "../../../utils/API";
import { generateInitialIndexId } from "../../../utils/indexIdUtils";
import {
  IAntDProTableRequest,
  IAPIResponse,
  IVocab,
  IVocabCreateRequest,
  IVocabUpdateRequest,
  SearchMeaningsResponse,
  SearchWordsResponse,
  WordOption,
} from "../type/VocabManagementType";

export const getVocabList = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  const queryParams = new URLSearchParams();

  if (params?.pageSize) {
    queryParams.append("limit", params.pageSize.toString());
  }

  if (params?.current) {
    queryParams.append("page", params.current.toString());
  }

  // Handle filter parameters
  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        queryParams.append(key, value.toString());
      }
    });
  }

  // Ensure that page and limit are always set
  if (!queryParams.has("page")) {
    queryParams.append("page", "1");
  }
  if (!queryParams.has("limit")) {
    queryParams.append("limit", "20");
  }

  const response = await apiRequest<IAPIResponse>(
    "GET",
    `/vocabs?${queryParams.toString()}`
  );

  return response.data;
};

export const getVocab = async (id: string | undefined) => {
  const response = await apiRequest<IVocab>("GET", `/words/${id}`);
  return response.data;
};

export const createVocab = async (data: FormData | IVocab) => {
  const contentType =
    data instanceof FormData ? "multipart/form-data" : "application/json";

  return await apiRequest<IVocab>("POST", "/words", data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

export const editVocab = async (id: string, data: FormData | IVocab) => {
  const contentType =
    data instanceof FormData ? "multipart/form-data" : "application/json";

  return await apiRequest<IVocab>("PATCH", `/words/${id}`, data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

export const archiveVocab = async (id: string) => {
  const response = await apiRequest<void>("PATCH", `/words/${id}/archive`);
  return response;
};

export const deleteOtherLanguage = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/otherLanguage/${id}`
  );
  return response;
};

export const searchWords = async (
  query: string,
  dictionaryId: string
): Promise<SearchWordsResponse> => {
  const queryParams = new URLSearchParams({
    limit: "10",
    page: "1",
    hanji: query,
    dictionaryId: dictionaryId,
  });

  const response = await apiRequest<SearchWordsResponse>(
    "GET",
    `/words/listOfHanjiWord?${queryParams.toString()}`
  );

  return response.data;
};

export const searchMeanings = async (
  query: string,
  dictionaryId: string
): Promise<SearchMeaningsResponse> => {
  const queryParams = new URLSearchParams({
    dictionaryId: dictionaryId,
    limit: "10",
    page: "1",
    meaningChinese: query,
  });

  const response = await apiRequest<SearchMeaningsResponse>(
    "GET",
    `/words/meanings?${queryParams.toString()}`
  );

  return response.data;
};

export const deleteWordSynonym = async (id: string) => {
  const response = await apiRequest<void>("DELETE", `/words/wordSynonym/${id}`);
  return response;
};

export const deleteWordAntonym = async (id: string) => {
  const response = await apiRequest<void>("DELETE", `/words/wordAntonym/${id}`);
  return response;
};

export const deleteMeaning = async (id: string) => {
  const response = await apiRequest<void>("DELETE", `/words/meaning/${id}`);
  return response;
};

export const deleteMeaningSynonym = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/meaningSynonym/${id}`
  );
  return response;
};

export const deleteMeaningAntonym = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/meaningAntonym/${id}`
  );
  return response;
};

export const deleteMeaningWordSynonym = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/meaningWordSynonym/${id}`
  );
  return response;
};

export const deleteMeaningWordAntonym = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/meaningWordAntonym/${id}`
  );
  return response;
};

export const deleteExample = async (id: string) => {
  const response = await apiRequest<void>("DELETE", `/words/example/${id}`);
  return response;
};

export const deleteAlternativeCharacter = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/alternativeCharacter/${id}`
  );
  return response;
};

export const deleteAlternativePronounce = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/alternativePronounce/${id}`
  );
  return response;
};

export const deleteMixWord = async (id: string) => {
  const response = await apiRequest<void>("DELETE", `/words/mixWord/${id}`);
  return response;
};

export const deleteCorruptedPronounce = async (id: string) => {
  const response = await apiRequest<void>(
    "DELETE",
    `/words/corruptedPronounce/${id}`
  );
  return response;
};

type IndexType =
  | "word"
  | "wordSynonym"
  | "wordAntonym"
  | "meaning"
  | "meaningSynonym"
  | "meaningAntonym"
  | "meaningWordSynonym"
  | "meaningWordAntonym"
  | "example"
  | "alternativeCharacter"
  | "alternativePronounce"
  | "mixWord"
  | "corruptedPronounce"
  | "accent";

export const getAllLatestIndexIds = async (
  dictionaryName: string
): Promise<Record<IndexType, string>> => {
  const indexTypes: IndexType[] = [
    "word",
    "wordSynonym",
    "wordAntonym",
    "meaning",
    "meaningSynonym",
    "meaningAntonym",
    "meaningWordSynonym",
    "meaningWordAntonym",
    "example",
    "alternativeCharacter",
    "alternativePronounce",
    "mixWord",
    "corruptedPronounce",
    "accent",
  ];

  const results = await Promise.all(
    indexTypes.map((type) => getLatestIndexIds(type, dictionaryName))
  );

  const latestIndexIds = indexTypes.reduce(
    (acc, type, index) => {
      acc[type] = results[index];
      return acc;
    },
    {} as Record<IndexType, string>
  );

  return latestIndexIds;
};

export const getLatestIndexIds = async (
  vocabIndexId: IndexType,
  dictionaryName: string
): Promise<string> => {
  const queryParams = new URLSearchParams({
    vocabIndexId,
    dictionaryName,
  });
  try {
    const response = await apiRequest<Record<string, string>>(
      "GET",
      `/words/vocabIndexId?${queryParams.toString()}`
    );

    const indexIdMap: Record<IndexType, string> = {
      word: "wordIndexId",
      wordSynonym: "wordSynonymIndexId",
      wordAntonym: "wordAntonymIndexId",
      meaning: "meaningIndexId",
      meaningSynonym: "meaningSynonymIndexId",
      meaningAntonym: "meaningAntonymIndexId",
      meaningWordSynonym: "meaningWordSynonymIndexId",
      meaningWordAntonym: "meaningWordAntonymIndexId",
      example: "exampleIndexId",
      alternativeCharacter: "alternativeCharacterIndexId",
      alternativePronounce: "alternativePronounceIndexId",
      mixWord: "mixWordIndexId",
      corruptedPronounce: "corruptedPronounceIndexId",
      accent: "accentIndexId",
    };

    return response.data[indexIdMap[vocabIndexId]] || "";
  } catch (error: any) {
    console.error("Error in getLatestIndexIds:", error);
    if (error.response && error.response.status === 404) {
      return generateInitialIndexId(dictionaryName, vocabIndexId);
    }
    return ""; // Return an empty string instead of throwing an error
  }
};

export const downloadSound = async (
  id: string
): Promise<{ data: Blob; filename: string }> => {
  const response = await apiRequest<Blob>(
    "GET",
    `/words/${id}/download`,
    {},
    {
      responseType: "blob",
    }
  );

  let filename = `vocab_sound_${id}.mp3`; // Default filename

  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return { data: response.data, filename };
};
